<form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
    <div *ngFor="let child of c.controls; let i = index">
        <div class="child-content">
            <div [formGroup]="child" class="three-columns">
                <div class="index-child">
                    {{i + 1}}
                </div>
                <div class="label">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" class="form-control"  type="text">
                        <mat-hint *ngIf="child.controls.name.touched  && child.controls.name.errors"  class="errorForm">
                            Name is required
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="label">
                    <mat-form-field appearance="outline">
                        <mat-label>Age</mat-label>
                        <input matInput formControlName="age" class="form-control" type="number">
                        <mat-hint *ngIf="child.controls.age.touched  && child.controls.age.errors" class="errorForm">
                            Age required
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="label"></div>
                <div class="label">
                    <mat-form-field appearance="outline">
                        <mat-label>Months in Canada</mat-label>
                        <input matInput formControlName="months" class="form-control" type="number">
                        <mat-hint *ngIf="child.controls.months.touched  && child.controls.months.errors" class="errorForm">
                            Months in Canada required
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="label">
                    <app-select [label]="'Province'" [options]="provinces" (onValueChange)="onProvinceChange(i,$event)"></app-select>
                </div>
                <div class="label"></div>
                <div class="label" *ngIf="c && c.controls[i] && c.controls[i].controls['province'] && c.controls[i].controls['province'].value && c.controls[i].controls['province'].value === 'British Columbia'">
                    <app-select [label]="'Regional District'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictChange(i,$event)"></app-select>
                </div>
                <div class="label">
                    <app-select [label]="'City'" [options]="cities" (onValueChange)="onCitiesChange(i,$event)"></app-select>
                </div>
                <div class="label"></div>

            </div>
        </div>
    </div>
    <button *ngIf="!dynamicForm.invalid" class="action-button" mat-button >Next</button>
</form>