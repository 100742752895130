<app-nav-bar></app-nav-bar>
<div class="header-bg">
    <div *ngIf="imageSection" class="text-header">
        {{imageSection.paragraph}}
        <button class="apply-button" routerLink="/form">Apply Now</button>
    </div>
</div>
<section class="aboutUs" id="aboutUs">
    <div class="about-us-content">
        <div class="left">
            <img src="/assets/img/leaf.png"alt="">
        </div>
        <div *ngIf="aboutSection" class="right">
            <h2 class="ican">{{aboutSection.title}}</h2>
            <p class="us" *ngFor="let paragraph of aboutSection.paragraphs">{{paragraph}}</p>
        </div>
    </div>
</section>
<section class="benefits" id="benefits" *ngIf="benefitSection">
    <h3 class="section-title">{{benefitSection.title}}</h3>
    <div class="benefits-content">
        <div class="benefit" *ngFor="let element of benefitSection.elements">
            <mat-icon>{{element.icon}}</mat-icon>
            <h3>{{element.title}}</h3>
            <p>{{element.paragraph}}</p>
        </div>
    </div>
</section>
<section class="howWorks" *ngIf="howItWorksSection" id="howItWorks">
    <h3 class="section-title">{{howItWorksSection.title}}</h3>
    <div class="howWorks-content">
        <div class="step" *ngFor="let element of howItWorksSection.elements">
            <div class="number">{{element.number}}</div>
            <div class="content">
                <mat-icon>{{element.icon}}</mat-icon>
                <p>{{element.paragraph}}</p>
            </div>
        </div>
    </div>
</section>
<footer>
    All rights reserved to AETGo
</footer>