import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './components/form/form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import {HttpClientModule} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { AutocompleteComponent } from './components/shared/autocomplete/autocomplete.component';
import { SelectComponent } from './components/shared/select/select.component';
import { SelectCountryComponent } from './components/shared/select-country/select-country.component';
import { FourSkillsComponent } from './components/shared/four-skills/four-skills.component';
import { GetChildrenComponent } from './components/shared/get-children/get-children.component';
import { MultipleSelectComponent } from './components/shared/multiple-select/multiple-select.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatMenuModule} from '@angular/material/menu';
import { SelectDateComponent } from './components/shared/select-date/select-date.component';
import { HomeComponent } from './components/home/home.component';
import { NavBarComponent } from './components/shared/nav-bar/nav-bar.component';
import { GetRelativesComponent } from './components/shared/get-relatives/get-relatives.component';
import { GetFriendsComponent } from './components/shared/get-friends/get-friends.component';
import { FamilyComponent } from './components/form/family/family.component';
import { PartnerComponent } from './components/form/partner/partner.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { DataSavedComponent } from './components/shared/data-saved/data-saved.component';
import { ViewerComponent } from './components/viewer/viewer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './components/shared/pdf-viewer/pdf-viewer.component';
import { PdfViewerFullCompleteComponent } from './components/shared/pdf-viewer-full-complete/pdf-viewer-full-complete.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FourSkillsFrenchComponent } from './components/shared/four-skills-french/four-skills-french.component';
import { NotprocessedComponent } from './components/notprocessed/notprocessed.component';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    AutocompleteComponent,
    SelectComponent,
    SelectCountryComponent,
    FourSkillsComponent,
    GetChildrenComponent,
    MultipleSelectComponent,
    SelectDateComponent,
    HomeComponent,
    NavBarComponent,
    GetRelativesComponent,
    GetFriendsComponent,
    FamilyComponent,
    PartnerComponent,
    LoaderComponent,
    DataSavedComponent,
    ViewerComponent,
    PdfViewerComponent,
    PdfViewerFullCompleteComponent,
    FourSkillsFrenchComponent,
    NotprocessedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule, 
    FormsModule, 
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectCountryModule.forRoot('en'),
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatStepperModule,
    MatMenuModule,
    MatDialogModule,
    PdfViewerModule,
    MatSnackBarModule,
  ],
  providers: [MatDatepickerModule,],
  bootstrap: [AppComponent]
})
export class AppModule { }
