import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit {

  @Input('label') label: string = "";
  @Input('eventSubmit') eventSubmit: Observable<void>;
  @Output() onValueChange = new EventEmitter();

  public errorFlag:boolean = false;
  public currentValue: any;
  
  constructor() { }

  ngOnInit(): void {
    if(this.eventSubmit) {
      this.eventSubmit.subscribe(() => this.validRequired());
    }
  }

  onChange(event:any) {
    const date = event && event.value ? event.value : null;
    this.currentValue = date;    
    this.errorFlag = date ? false : true;
    this.onValueChange.emit(date);    
  }

  validRequired() {
    if(!this.currentValue) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
    }
  }

}
