
export const hostProd =
  "https://us-central1-migration-to-canada.cloudfunctions.net/app";

export const hostLocal =
  "http://localhost:5000/migration-to-canada/us-central1/app";

export const host = hostProd;

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA4SLGxWcR4sGfVsBREjmgI9GlaaA3kh7o",
    authDomain: "migration-to-canada.firebaseapp.com",
    databaseURL: "https://migration-to-canada-default-rtdb.firebaseio.com",
    projectId: "migration-to-canada",
    storageBucket: "migration-to-canada.appspot.com",
    messagingSenderId: "378223393098",
    appId: "1:378223393098:web:b65aa610049a1489dee9b3",
    measurementId: "${config.measurementId}"
  },
};