<app-loader *ngIf="isLoading"></app-loader>
<app-nav-bar></app-nav-bar>
<div class="body-container">
    <span class="icon-payment">
        <mat-icon>mood_bad</mat-icon>
    </span>
    <p class="unfortunately">
        Unfortunately, your payment has not been processed successfully.
    </p>
    <button class="payment-button" (click)="openPayment()">Try again</button>
</div>