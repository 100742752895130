import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICatalogItem, IFourSkills, ITCFCanada, ITEFCanada } from '../../form/models';
import { get as _get } from 'lodash';

@Component({
  selector: 'app-four-skills-french',
  templateUrl: './four-skills-french.component.html',
  styleUrls: ['./four-skills-french.component.scss']
})
export class FourSkillsFrenchComponent implements OnInit {

  @Input('options') options: ITCFCanada | ITEFCanada;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();

  public readingOptions: Array<ICatalogItem>;
  public writtingOptions: Array<ICatalogItem>;
  public listeningOptions: Array<ICatalogItem>;
  public speakingOptions: Array<ICatalogItem>;

  public readingOption: ICatalogItem;
  public writtingOption: ICatalogItem;
  public listeningOption: ICatalogItem;
  public speakingOption: ICatalogItem;
  public defaultOption: any;

  constructor() { }

  ngOnInit(): void {

    this.setDefaultValues();
  }

  setDefaultValues() {
    this.readingOptions = _get(this.options,'reading') || [];
    this.writtingOptions = _get(this.options,'writing') || [];
    this.listeningOptions = _get(this.options,'listening') || [];
    this.speakingOptions = _get(this.options,'speaking') || [];

    this.readingOption = this.readingOptions.find(o => _get(o,'uid') === 0);
    this.writtingOption = this.writtingOptions.find(o => _get(o,'uid') === 0);
    this.listeningOption = this.listeningOptions.find(o => _get(o,'uid') === 0);
    this.speakingOption = this.speakingOptions.find(o => _get(o,'uid') === 0);
    
    this.valueChanged();
  }

  onReadingChange(option:any) {
    this.readingOption = option;
    this.valueChanged();
  }

  onWrittingChange(option:any) {
    this.writtingOption = option;
    this.valueChanged();
  }

  onListeningChange(option:any) {
    this.listeningOption = option;
    this.valueChanged();
  }

  onSpeakingChange(option:any) {
    this.speakingOption = option;
    this.valueChanged();
  }

  valueChanged() {
    const data:IFourSkills = {
      reading: _get(this.readingOption,'uid') || 0,
      writing: _get(this.writtingOption,'uid') || 0,
      listening: _get(this.listeningOption,'uid') || 0,
      speaking: _get(this.speakingOption,'uid') || 0,
    }
    this.onValueChange.emit(data);
  }
}
