import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit {

  @Input('label') label: string = "";
  @Input('showCallingCode') showCallingCode: boolean = false;
  @Input('eventSubmit') eventSubmit: Observable<void>;

  @Output() onValueChange = new EventEmitter();

  public errorFlag:boolean = false;
  public currentValue: any;

  constructor() { }

  ngOnInit(): void {
    if(this.eventSubmit) {
      this.eventSubmit.subscribe(() => this.validRequired());
    }
  }

  onCountryCodeChange(selected:any) {
    this.errorFlag = false;
    const selectedValue = selected && selected ? selected : null;
    this.currentValue = selectedValue;
    this.onValueChange.emit(selectedValue);    
  }

  cleanValue() {
    this.errorFlag = true;
    this.currentValue = null;
    this.onValueChange.emit(null);
  }

  validRequired() {
    if(!this.currentValue) {
      this.errorFlag = true;
    }
  }

}
