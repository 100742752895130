import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { get as _get } from 'lodash';
import { IChildren, IFamily } from '../models';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit {

  @Output() onSubmitClicked: EventEmitter<IFamily> = new EventEmitter();

  constructor() { }

  public familySize: number;
  public childrenNumber: number;

  public wasSubmitClicked: boolean;

  ngOnInit(): void {}

  onSubmitFamily(data: any) {
    const dataChildren = _get(data,'children') || [];
    const children = dataChildren.map(c => {
      const data: IChildren = {
        name: _get(c,'name') || '',
        isLivingInCanada: _get(c,'livingInCanada') || false,
        province: _get(c,'province') || '',
        regionalDistrict: _get(c,'regionalDistrict') || '',
        isStudingInCanada: _get(c,'studingInCanada') || false,
      }
      return data;
    });
    const family:IFamily = {
      children: children,
      size: this.familySize || 0,
    }
    this.onSubmitClicked.next(family);    
  }

}
