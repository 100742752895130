import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICatalogItem, IChannel, ICity, IDataUser, ILanguage, IOccupation, ISchoolLevel, ISimpleCatalog, ITCFCanada, ITEFCanada } from './models';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { host } from 'src/app/constant';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private host: string = host;

  constructor(
    private http: HttpClient,
  ) { }

  getOptionsToStudy(): Observable<Array<ICatalogItem>> {
    const options = this.getOccupationOptions();
    return of(options);
  }

  getRelationshipStatus(): Array<ICatalogItem> {
    const options = [
      {uid:'single', label: 'Single'},
      {uid:'engaged', label: 'Engaged'},
      {uid:'married', label: 'Married'},
      {uid:'separated', label: 'Separated'},
      {uid:'divorced', label: 'Divorced'},
      {uid:'widowed', label: 'Widowed'},
    ];
    return options;
  }

  getEnglishCertificationTypes(): Array<ICatalogItem> {
    const options = [
      {uid:'IELTS', label: 'IELTS'},
      {uid:'TOEFL', label: 'TOEFL'},
      {uid:'Duolingo', label: 'Duolingo'},
      {uid:'Other', label: 'Other'},
    ];
    return options;
  }

  getFrenchCertificationTypes(): Array<ICatalogItem> {
    const options = [
      {uid:'TEF', label: 'TEF Canada'},
      {uid:'TCF', label: 'TCF Canada'},
      {uid:'Other', label: 'Other'},
    ];
    return options;
  }

  getOtherLanguageLevels():  Array<ICatalogItem> {
    const options = [
      {uid:'beginner', label: 'Beginner'},
      {uid:'A1', label: 'A1'},
      {uid:'A2', label: 'A2'},
      {uid:'B1', label: 'B1'},
      {uid:'B2', label: 'B2'},
      {uid:'C1', label: 'C1'},
      {uid:'C2', label: 'C2'},
    ];
    return options;
  }

  getSkillScore(): Array<ICatalogItem> {
    const options = [
      {uid:9, label: '9'},
      {uid:8.5, label: '8.5'},
      {uid:8, label: '8'},
      {uid:7.5, label: '7.5'},
      {uid:7, label: '7'},
      {uid:6.5, label: '6.5'},
      {uid:6, label: '6'},
      {uid:5.5, label: '5.5'},
      {uid:5, label: '5'},
      {uid:4.5, label: '4.5'},
      {uid:4, label: '4'},
      {uid:3.5, label: '3.5'},
      {uid:3, label: '3'},
      {uid:2, label: '2'},
      {uid:1, label: '1'},
      {uid:0, label: '0'},
    ];
    return options;
  }

  getSkillScoreTCF(): ITCFCanada {
    const reading = [
      {uid:549, label: '549 - 699'},
      {uid:524, label: '524 - 548'},
      {uid:499, label: '499 - 523'},
      {uid:453, label: '453 - 498'},
      {uid:406, label: '406 - 452'},
      {uid:375, label: '375 - 405'},
      {uid:342, label: '342 - 374'},
      {uid:0, label: '0 - 341'},
    ];
    const writing = [
      {uid:16, label: '16 - 20'},
      {uid:14, label: '14 - 15'},
      {uid:12, label: '12 - 13'},
      {uid:10, label: '10 - 11'},
      {uid:7, label: '7 - 9'},
      {uid:6, label: '6'},
      {uid:4, label: '4 - 5'},
      {uid:0, label: '0 - 3'},
    ];
    const listening = [
      {uid:549, label: '549 - 699'},
      {uid:523, label: '523 - 548'},
      {uid:503, label: '503 - 522'},
      {uid:458, label: '458 - 502'},
      {uid:398, label: '398 - 457'},
      {uid:369, label: '369 - 397'},
      {uid:331, label: '331 - 368'},
      {uid:0, label: '0 - 330'},
    ];
    const speaking = [
      {uid:16, label: '16 - 20'},
      {uid:14, label: '14 - 15'},
      {uid:12, label: '12 - 13'},
      {uid:10, label: '10 - 11'},
      {uid:7, label: '7 - 9'},
      {uid:6, label: '6'},
      {uid:4, label: '4 - 5'},
      {uid:0, label: '0 - 3'},
    ];
    const options = {
      reading,
      writing,
      listening,
      speaking,
    }
    return options;
  }

  getSkillScoreTEF(): ITEFCanada {
    const reading = [
      {uid:263, label: '263 - 300'},
      {uid:248, label: '248 - 262'},
      {uid:233, label: '233 - 247'},
      {uid:207, label: '207 - 232'},
      {uid:181, label: '181 - 206'},
      {uid:151, label: '151 - 180'},
      {uid:121, label: '121 - 150'},
      {uid:0, label: '0 - 120'},
    ];
    const writing = [
      {uid:393, label: '393 - 450'},
      {uid:371, label: '371 - 392'},
      {uid:349, label: '349 - 370'},
      {uid:310, label: '310 - 348'},
      {uid:271, label: '271 - 309'},
      {uid:226, label: '226 - 270'},
      {uid:181, label: '181 - 225'},
      {uid:0, label: '0 - 180'},
    ];
    const listening = [
      {uid:316, label: '316 - 360'},
      {uid:298, label: '298 - 315'},
      {uid:280, label: '280 - 297'},
      {uid:249, label: '249 - 279'},
      {uid:217, label: '217 - 248'},
      {uid:181, label: '181 - 216'},
      {uid:145, label: '145 - 180'},
      {uid:0, label: '0 - 144'},
    ];
    const speaking = [
      {uid:393, label: '393 - 450'},
      {uid:371, label: '371 - 392'},
      {uid:349, label: '349 - 370'},
      {uid:310, label: '310 - 348'},
      {uid:271, label: '271 - 309'},
      {uid:226, label: '226 - 270'},
      {uid:181, label: '181 - 225'},
      {uid:0, label: '0 - 180'},
    ];
    const options = {
      reading,
      writing,
      listening,
      speaking,
    }
    return options;
  }

  getNumbersOptions(): Array<ICatalogItem> {
    const options = [
      {uid:0, label: '0'},
      {uid:1, label: '1'},
      {uid:2, label: '2'},
      {uid:3, label: '3'},
      {uid:4, label: '4'},
      {uid:5, label: '5'},
      {uid:6, label: '6'},
      {uid:7, label: '7'},
      {uid:8, label: '8'},
      {uid:9, label: '9'},
    ];
    return options;
  }

  getFamilyMembers(): Array<ICatalogItem> {
    const options = [
      {uid:'Spouse', label: 'Spouse'},
      {uid:'Common', label: 'Common-law partner'},
      {uid:'Dependent', label: 'Dependent child'},
      {uid:'Grandchild', label: 'Grandchild'},
    ];
    return options;
  }

  getExtendedFamilyMembers(): Array<ICatalogItem> {
    const options = [
      {uid:'partner', label: 'Boyfriend or Girlfriend dating for at least 1 year'},
      {uid:'dependentChild', label: 'Dependent Child'},
      {uid:'childPerson', label: 'Child of the person, of their spouse or common-law partner or of the person in the exclusive dating relationship'},
      {uid:'granchild', label: 'Granchild'},
      {uid:'sibling', label: 'Sibling, half-sibling or step-sibling of your sentimental partner'},
      {uid:'grandparent', label: 'Grandparent'},
      {uid:'grandparentPartner', label: 'Grandparent of your sentimental partner'},
    ];
    return options;
  }

  getOccupationOptions(): Array<ICatalogItem> {
    const options = [
      {uid:"0011", label:"Legislators", group:"0"},
      {uid:"0012", label:"Senior government managers and officials", group:"0"},
      {uid:"0013", label:"Senior managers - financial, communications and other business services", group:"0"},
      {uid:"0014", label:"Senior managers - health, education, social and community services and membership organizations", group:"0"},
      {uid:"0015", label:"Senior managers - trade, broadcasting and other services, n.e.c.", group:"0"},
      {uid:"0016", label:"Senior managers - construction, transportation, production and utilities", group:"0"},
      {uid:"0111", label:"Financial managers", group:"0"},
      {uid:"0112", label:"Human resources managers", group:"0"},
      {uid:"0113", label:"Purchasing managers", group:"0"},
      {uid:"0114", label:"Other administrative services managers", group:"0"},
      {uid:"0121", label:"Insurance, real estate and financial brokerage managers", group:"0"},
      {uid:"0122", label:"Banking, credit and other investment managers", group:"0"},
      {uid:"0124", label:"Advertising, marketing and public relations managers", group:"0"},
      {uid:"0125", label:"Other business services managers", group:"0"},
      {uid:"0131", label:"Telecommunication carriers managers", group:"0"},
      {uid:"0132", label:"Postal and courier services managers", group:"0"},
      {uid:"0211", label:"Engineering managers", group:"0"},
      {uid:"0212", label:"Architecture and science managers", group:"0"},
      {uid:"0213", label:"Computer and information systems managers", group:"0"},
      {uid:"0311", label:"Managers in health care", group:"0"},
      {uid:"0411", label:"Government managers - health and social policy development and program administration", group:"0"},
      {uid:"0412", label:"Government managers - economic analysis, policy development and program administration", group:"0"},
      {uid:"0413", label:"Government managers - education policy development and program administration", group:"0"},
      {uid:"0414", label:"Other managers in public administration", group:"0"},
      {uid:"0421", label:"Administrators - post-secondary education and vocational training", group:"0"},
      {uid:"0422", label:"School principals and administrators of elementary and secondary education", group:"0"},
      {uid:"0423", label:"Managers in social, community and correctional services", group:"0"},
      {uid:"0431", label:"Commissioned police officers", group:"0"},
      {uid:"0432", label:"Fire chiefs and senior firefighting officers", group:"0"},
      {uid:"0433", label:"Commissioned officers of the Canadian Armed Forces", group:"0"},
      {uid:"0511", label:"Library, archive, museum and art gallery managers", group:"0"},
      {uid:"0512", label:"Managers - publishing, motion pictures, broadcasting and performing arts", group:"0"},
      {uid:"0513", label:"Recreation, sports and fitness program and service directors", group:"0"},
      {uid:"0601", label:"Corporate sales managers", group:"0"},
      {uid:"0621", label:"Retail and wholesale trade managers", group:"0"},
      {uid:"0631", label:"Restaurant and food service managers", group:"0"},
      {uid:"0632", label:"Accommodation service managers", group:"0"},
      {uid:"0651", label:"Managers in customer and personal services, n.e.c.", group:"0"},
      {uid:"0711", label:"Construction managers", group:"0"},
      {uid:"0712", label:"Home building and renovation managers", group:"0"},
      {uid:"0714", label:"Facility operation and maintenance managers", group:"0"},
      {uid:"0731", label:"Managers in transportation", group:"0"},
      {uid:"0811", label:"Managers in natural resources production and fishing", group:"0"},
      {uid:"0821", label:"Managers in agriculture", group:"0"},
      {uid:"0822", label:"Managers in horticulture", group:"0"},
      {uid:"0823", label:"Managers in aquaculture", group:"0"},
      {uid:"0911", label:"Manufacturing managers", group:"0"},
      {uid:"0912", label:"Utilities managers", group:"0"},
      {uid:"1111", label:"Financial auditors and accountants", group:"A"},
      {uid:"1112", label:"Financial and investment analysts", group:"A"},
      {uid:"1113", label:"Securities agents, investment dealers and brokers", group:"A"},
      {uid:"1114", label:"Other financial officers", group:"A"},
      {uid:"1121", label:"Human resources professionals", group:"A"},
      {uid:"1122", label:"Professional occupations in business management consulting", group:"A"},
      {uid:"1123", label:"Professional occupations in advertising, marketing and public relations", group:"A"},
      {uid:"1211", label:"Supervisors, general office and administrative support workers", group:"B"},
      {uid:"1212", label:"Supervisors, finance and insurance office workers", group:"B"},
      {uid:"1213", label:"Supervisors, library, correspondence and related information workers", group:"B"},
      {uid:"1214", label:"Supervisors, mail and message distribution occupations", group:"B"},
      {uid:"1215", label:"Supervisors, supply chain, tracking and scheduling co-ordination occupations", group:"B"},
      {uid:"1221", label:"Administrative officers", group:"B"},
      {uid:"1222", label:"Executive assistants", group:"B"},
      {uid:"1223", label:"Human resources and recruitment officers", group:"B"},
      {uid:"1224", label:"Property administrators", group:"B"},
      {uid:"1225", label:"Purchasing agents and officers", group:"B"},
      {uid:"1226", label:"Conference and event planners", group:"B"},
      {uid:"1227", label:"Court officers and justices of the peace", group:"B"},
      {uid:"1228", label:"Employment insurance, immigration, border services and revenue officers", group:"B"},
      {uid:"1241", label:"Administrative assistants", group:"B"},
      {uid:"1242", label:"Legal administrative assistants", group:"B"},
      {uid:"1243", label:"Medical administrative assistants", group:"B"},
      {uid:"1251", label:"Court reporters, medical transcriptionists and related occupations", group:"B"},
      {uid:"1252", label:"Health information management occupations", group:"B"},
      {uid:"1253", label:"Records management technicians", group:"B"},
      {uid:"1254", label:"Statistical officers and related research support occupations", group:"B"},
      {uid:"1311", label:"Accounting technicians and bookkeepers", group:"B"},
      {uid:"1312", label:"Insurance adjusters and claims examiners", group:"B"},
      {uid:"1313", label:"Insurance underwriters", group:"B"},
      {uid:"1314", label:"Assessors, valuators and appraisers", group:"B"},
      {uid:"1315", label:"Customs, ship and other brokers", group:"B"},
      {uid:"1411", label:"General office support workers", group:"C"},
      {uid:"1414", label:"Receptionists", group:"C"},
      {uid:"1415", label:"Personnel clerks", group:"C"},
      {uid:"1416", label:"Court clerks", group:"C"},
      {uid:"1422", label:"Data entry clerks", group:"C"},
      {uid:"1423", label:"Desktop publishing operators and related occupations", group:"C"},
      {uid:"1431", label:"Accounting and related clerks", group:"C"},
      {uid:"1432", label:"Payroll administrators", group:"C"},
      {uid:"1434", label:"Banking, insurance and other financial clerks", group:"C"},
      {uid:"1435", label:"Collectors", group:"C"},
      {uid:"1451", label:"Library assistants and clerks", group:"C"},
      {uid:"1452", label:"Correspondence, publication and regulatory clerks", group:"C"},
      {uid:"1454", label:"Survey interviewers and statistical clerks", group:"C"},
      {uid:"1511", label:"Mail, postal and related workers", group:"C"},
      {uid:"1512", label:"Letter carriers", group:"C"},
      {uid:"1513", label:"Couriers, messengers and door-to-door distributors", group:"C"},
      {uid:"1521", label:"Shippers and receivers", group:"C"},
      {uid:"1522", label:"Storekeepers and partspersons", group:"C"},
      {uid:"1523", label:"Production logistics co-ordinators", group:"C"},
      {uid:"1524", label:"Purchasing and inventory control workers", group:"C"},
      {uid:"1525", label:"Dispatchers", group:"C"},
      {uid:"1526", label:"Transportation route and crew schedulers", group:"C"},
      {uid:"2111", label:"Physicists and astronomers", group:"A"},
      {uid:"2112", label:"Chemists", group:"A"},
      {uid:"2113", label:"Geoscientists and oceanographers", group:"A"},
      {uid:"2114", label:"Meteorologists and climatologists", group:"A"},
      {uid:"2115", label:"Other professional occupations in physical sciences", group:"A"},
      {uid:"2121", label:"Biologists and related scientists", group:"A"},
      {uid:"2122", label:"Forestry professionals", group:"A"},
      {uid:"2123", label:"Agricultural representatives, consultants and specialists", group:"A"},
      {uid:"2131", label:"Civil engineers", group:"A"},
      {uid:"2132", label:"Mechanical engineers", group:"A"},
      {uid:"2133", label:"Electrical and electronics engineers", group:"A"},
      {uid:"2134", label:"Chemical engineers", group:"A"},
      {uid:"2141", label:"Industrial and manufacturing engineers", group:"A"},
      {uid:"2142", label:"Metallurgical and materials engineers", group:"A"},
      {uid:"2143", label:"Mining engineers", group:"A"},
      {uid:"2144", label:"Geological engineers", group:"A"},
      {uid:"2145", label:"Petroleum engineers", group:"A"},
      {uid:"2146", label:"Aerospace engineers", group:"A"},
      {uid:"2147", label:"Computer engineers (except software engineers and designers)", group:"A"},
      {uid:"2148", label:"Other professional engineers, n.e.c.", group:"A"},
      {uid:"2151", label:"Architects", group:"A"},
      {uid:"2152", label:"Landscape architects", group:"A"},
      {uid:"2153", label:"Urban and land use planners", group:"A"},
      {uid:"2154", label:"Land surveyors", group:"A"},
      {uid:"2161", label:"Mathematicians, statisticians and actuaries", group:"A"},
      {uid:"2171", label:"Information systems analysts and consultants", group:"A"},
      {uid:"2172", label:"Database analysts and data administrators", group:"A"},
      {uid:"2173", label:"Software engineers and designers", group:"A"},
      {uid:"2174", label:"Computer programmers and interactive media developers", group:"A"},
      {uid:"2175", label:"Web designers and developers", group:"A"},
      {uid:"2211", label:"Chemical technologists and technicians", group:"B"},
      {uid:"2212", label:"Geological and mineral technologists and technicians", group:"B"},
      {uid:"2221", label:"Biological technologists and technicians", group:"B"},
      {uid:"2222", label:"Agricultural and fish products inspectors", group:"B"},
      {uid:"2223", label:"Forestry technologists and technicians", group:"B"},
      {uid:"2224", label:"Conservation and fishery officers", group:"B"},
      {uid:"2225", label:"Landscape and horticulture technicians and specialists", group:"B"},
      {uid:"2231", label:"Civil engineering technologists and technicians", group:"B"},
      {uid:"2232", label:"Mechanical engineering technologists and technicians", group:"B"},
      {uid:"2233", label:"Industrial engineering and manufacturing technologists and technicians", group:"B"},
      {uid:"2234", label:"Construction estimators", group:"B"},
      {uid:"2241", label:"Electrical and electronics engineering technologists and technicians", group:"B"},
      {uid:"2242", label:"Electronic service technicians (household and business equipment)", group:"B"},
      {uid:"2243", label:"Industrial instrument technicians and mechanics", group:"B"},
      {uid:"2244", label:"Aircraft instrument, electrical and avionics mechanics, technicians and inspectors", group:"B"},
      {uid:"2251", label:"Architectural technologists and technicians", group:"B"},
      {uid:"2252", label:"Industrial designers", group:"B"},
      {uid:"2253", label:"Drafting technologists and technicians", group:"B"},
      {uid:"2254", label:"Land survey technologists and technicians", group:"B"},
      {uid:"2255", label:"Technical occupations in geomatics and meteorology", group:"B"},
      {uid:"2261", label:"Non-destructive testers and inspection technicians", group:"B"},
      {uid:"2262", label:"Engineering inspectors and regulatory officers", group:"B"},
      {uid:"2263", label:"Inspectors in public and environmental health and occupational health and safety", group:"B"},
      {uid:"2264", label:"Construction inspectors", group:"B"},
      {uid:"2271", label:"Air pilots, flight engineers and flying instructors", group:"B"},
      {uid:"2272", label:"Air traffic controllers and related occupations", group:"B"},
      {uid:"2273", label:"Deck officers, water transport", group:"B"},
      {uid:"2274", label:"Engineer officers, water transport", group:"B"},
      {uid:"2275", label:"Railway traffic controllers and marine traffic regulators", group:"B"},
      {uid:"2281", label:"Computer network technicians", group:"B"},
      {uid:"2282", label:"User support technicians", group:"B"},
      {uid:"2283", label:"Information systems testing technicians", group:"B"},
      {uid:"3011", label:"Nursing co-ordinators and supervisors", group:"A"},
      {uid:"3012", label:"Registered nurses and registered psychiatric nurses", group:"A"},
      {uid:"3111", label:"Specialist physicians", group:"A"},
      {uid:"3112", label:"General practitioners and family physicians", group:"A"},
      {uid:"3113", label:"Dentists", group:"A"},
      {uid:"3114", label:"Veterinarians", group:"A"},
      {uid:"3121", label:"Optometrists", group:"A"},
      {uid:"3122", label:"Chiropractors", group:"A"},
      {uid:"3124", label:"Allied primary health practitioners", group:"A"},
      {uid:"3125", label:"Other professional occupations in health diagnosing and treating", group:"A"},
      {uid:"3131", label:"Pharmacists", group:"A"},
      {uid:"3132", label:"Dietitians and nutritionists", group:"A"},
      {uid:"3141", label:"Audiologists and speech-language pathologists", group:"A"},
      {uid:"3142", label:"Physiotherapists", group:"A"},
      {uid:"3143", label:"Occupational therapists", group:"A"},
      {uid:"3144", label:"Other professional occupations in therapy and assessment", group:"A"},
      {uid:"3211", label:"Medical laboratory technologists", group:"B"},
      {uid:"3212", label:"Medical laboratory technicians and pathologists' assistants", group:"B"},
      {uid:"3213", label:"Animal health technologists and veterinary technicians", group:"B"},
      {uid:"3214", label:"Respiratory therapists, clinical perfusionists and cardiopulmonary technologists", group:"B"},
      {uid:"3215", label:"Medical radiation technologists", group:"B"},
      {uid:"3216", label:"Medical sonographers", group:"B"},
      {uid:"3217", label:"Cardiology technologists and electrophysiological diagnostic technologists, n.e.c.", group:"B"},
      {uid:"3219", label:"Other medical technologists and technicians (except dental health)", group:"B"},
      {uid:"3221", label:"Denturists", group:"B"},
      {uid:"3222", label:"Dental hygienists and dental therapists", group:"B"},
      {uid:"3223", label:"Dental technologists, technicians and laboratory assistants", group:"B"},
      {uid:"3231", label:"Opticians", group:"B"},
      {uid:"3232", label:"Practitioners of natural healing", group:"B"},
      {uid:"3233", label:"Licensed practical nurses", group:"B"},
      {uid:"3234", label:"Paramedical occupations", group:"B"},
      {uid:"3236", label:"Massage therapists", group:"B"},
      {uid:"3237", label:"Other technical occupations in therapy and assessment", group:"B"},
      {uid:"3411", label:"Dental assistants", group:"C"},
      {uid:"3413", label:"Nurse aides, orderlies and patient service associates", group:"C"},
      {uid:"3414", label:"Other assisting occupations in support of health services", group:"C"},
      {uid:"4011", label:"University professors and lecturers", group:"A"},
      {uid:"4012", label:"Post-secondary teaching and research assistants", group:"A"},
      {uid:"4021", label:"College and other vocational instructors", group:"A"},
      {uid:"4031", label:"Secondary school teachers", group:"A"},
      {uid:"4032", label:"Elementary school and kindergarten teachers", group:"A"},
      {uid:"4033", label:"Educational counsellors", group:"A"},
      {uid:"4111", label:"Judges", group:"A"},
      {uid:"4112", label:"Lawyers and Quebec notaries", group:"A"},
      {uid:"4151", label:"Psychologists", group:"A"},
      {uid:"4152", label:"Social workers", group:"A"},
      {uid:"4153", label:"Family, marriage and other related counsellors", group:"A"},
      {uid:"4154", label:"Professional occupations in religion", group:"A"},
      {uid:"4155", label:"Probation and parole officers and related occupations", group:"A"},
      {uid:"4156", label:"Employment counsellors", group:"A"},
      {uid:"4161", label:"Natural and applied science policy researchers, consultants and program officers", group:"A"},
      {uid:"4162", label:"Economists and economic policy researchers and analysts", group:"A"},
      {uid:"4163", label:"Business development officers and marketing researchers and consultants", group:"A"},
      {uid:"4164", label:"Social policy researchers, consultants and program officers", group:"A"},
      {uid:"4165", label:"Health policy researchers, consultants and program officers", group:"A"},
      {uid:"4166", label:"Education policy researchers, consultants and program officers", group:"A"},
      {uid:"4167", label:"Recreation, sports and fitness policy researchers, consultants and program officers", group:"A"},
      {uid:"4168", label:"Program officers unique to government", group:"A"},
      {uid:"4169", label:"Other professional occupations in social science, n.e.c.", group:"A"},
      {uid:"4211", label:"Paralegal and related occupations", group:"B"},
      {uid:"4212", label:"Social and community service workers", group:"B"},
      {uid:"4214", label:"Early childhood educators and assistants", group:"B"},
      {uid:"4215", label:"Instructors of persons with disabilities", group:"B"},
      {uid:"4216", label:"Other instructors", group:"B"},
      {uid:"4217", label:"Other religious occupations", group:"B"},
      {uid:"4311", label:"Police officers (except commissioned)", group:"B"},
      {uid:"4312", label:"Firefighters", group:"B"},
      {uid:"4313", label:"Non-commissioned ranks of the Canadian Armed Forces", group:"B"},
      {uid:"4411", label:"Home child care providers", group:"C"},
      {uid:"4412", label:"Home support workers, housekeepers and related occupations", group:"C"},
      {uid:"4413", label:"Elementary and secondary school teacher assistants", group:"C"},
      {uid:"4421", label:"Sheriffs and bailiffs", group:"C"},
      {uid:"4422", label:"Correctional service officers", group:"C"},
      {uid:"4423", label:"By-law enforcement and other regulatory officers, n.e.c.", group:"C"},
      {uid:"5111", label:"Librarians", group:"A"},
      {uid:"5112", label:"Conservators and curators", group:"A"},
      {uid:"5113", label:"Archivists", group:"A"},
      {uid:"5121", label:"Authors and writers", group:"A"},
      {uid:"5122", label:"Editors", group:"A"},
      {uid:"5123", label:"Journalists", group:"A"},
      {uid:"5125", label:"Translators, terminologists and interpreters", group:"A"},
      {uid:"5131", label:"Producers, directors, choreographers and related occupations", group:"A"},
      {uid:"5132", label:"Conductors, composers and arrangers", group:"A"},
      {uid:"5133", label:"Musicians and singers", group:"A"},
      {uid:"5134", label:"Dancers", group:"A"},
      {uid:"5135", label:"Actors and comedians", group:"A"},
      {uid:"5136", label:"Painters, sculptors and other visual artists", group:"A"},
      {uid:"5211", label:"Library and public archive technicians", group:"B"},
      {uid:"5212", label:"Technical occupations related to museums and art galleries", group:"B"},
      {uid:"5221", label:"Photographers", group:"B"},
      {uid:"5222", label:"Film and video camera operators", group:"B"},
      {uid:"5223", label:"Graphic arts technicians", group:"B"},
      {uid:"5224", label:"Broadcast technicians", group:"B"},
      {uid:"5225", label:"Audio and video recording technicians", group:"B"},
      {uid:"5226", label:"Other technical and co-ordinating occupations in motion pictures, broadcasting and the performing arts", group:"B"},
      {uid:"5227", label:"Support occupations in motion pictures, broadcasting, photography and the performing arts", group:"B"},
      {uid:"5231", label:"Announcers and other broadcasters", group:"B"},
      {uid:"5232", label:"Other performers, n.e.c.", group:"B"},
      {uid:"5241", label:"Graphic designers and illustrators", group:"B"},
      {uid:"5242", label:"Interior designers and interior decorators", group:"B"},
      {uid:"5243", label:"Theatre, fashion, exhibit and other creative designers", group:"B"},
      {uid:"5244", label:"Artisans and craftspersons", group:"B"},
      {uid:"5245", label:"Patternmakers - textile, leather and fur products", group:"B"},
      {uid:"5251", label:"Athletes", group:"B"},
      {uid:"5252", label:"Coaches", group:"B"},
      {uid:"5253", label:"Sports officials and referees", group:"B"},
      {uid:"5254", label:"Program leaders and instructors in recreation, sport and fitness", group:"B"},
      {uid:"6211", label:"Retail sales supervisors", group:"B"},
      {uid:"6221", label:"Technical sales specialists - wholesale trade", group:"B"},
      {uid:"6222", label:"Retail and wholesale buyers", group:"B"},
      {uid:"6231", label:"Insurance agents and brokers", group:"B"},
      {uid:"6232", label:"Real estate agents and salespersons", group:"B"},
      {uid:"6235", label:"Financial sales representatives", group:"B"},
      {uid:"6311", label:"Food service supervisors", group:"B"},
      {uid:"6312", label:"Executive housekeepers", group:"B"},
      {uid:"6313", label:"Accommodation, travel, tourism and related services supervisors", group:"B"},
      {uid:"6314", label:"Customer and information services supervisors", group:"B"},
      {uid:"6315", label:"Cleaning supervisors", group:"B"},
      {uid:"6316", label:"Other services supervisors", group:"B"},
      {uid:"6321", label:"Chefs", group:"B"},
      {uid:"6322", label:"Cooks", group:"B"},
      {uid:"6331", label:"Butchers, meat cutters and fishmongers - retail and wholesale", group:"B"},
      {uid:"6332", label:"Bakers", group:"B"},
      {uid:"6341", label:"Hairstylists and barbers", group:"B"},
      {uid:"6342", label:"Tailors, dressmakers, furriers and milliners", group:"B"},
      {uid:"6343", label:"Shoe repairers and shoemakers", group:"B"},
      {uid:"6344", label:"Jewellers, jewellery and watch repairers and related occupations", group:"B"},
      {uid:"6345", label:"Upholsterers", group:"B"},
      {uid:"6346", label:"Funeral directors and embalmers", group:"B"},
      {uid:"6411", label:"Sales and account representatives - wholesale trade (non-technical)", group:"C"},
      {uid:"6421", label:"Retail salespersons", group:"C"},
      {uid:"6511", label:"Maîtres d'hôtel and hosts/hostesses", group:"C"},
      {uid:"6512", label:"Bartenders", group:"C"},
      {uid:"6513", label:"Food and beverage servers", group:"C"},
      {uid:"6521", label:"Travel counsellors", group:"C"},
      {uid:"6522", label:"Pursers and flight attendants", group:"C"},
      {uid:"6523", label:"Airline ticket and service agents", group:"C"},
      {uid:"6524", label:"Ground and water transport ticket agents, cargo service representatives and related clerks", group:"C"},
      {uid:"6525", label:"Hotel front desk clerks", group:"C"},
      {uid:"6531", label:"Tour and travel guides", group:"C"},
      {uid:"6532", label:"Outdoor sport and recreational guides", group:"C"},
      {uid:"6533", label:"Casino occupations", group:"C"},
      {uid:"6541", label:"Security guards and related security service occupations", group:"C"},
      {uid:"6551", label:"Customer services representatives - financial institutions", group:"C"},
      {uid:"6552", label:"Other customer and information services representatives", group:"C"},
      {uid:"6561", label:"Image, social and other personal consultants", group:"C"},
      {uid:"6562", label:"Estheticians, electrologists and related occupations", group:"C"},
      {uid:"6563", label:"Pet groomers and animal care workers", group:"C"},
      {uid:"6564", label:"Other personal service occupations", group:"C"},
      {uid:"6611", label:"Cashiers", group:"D"},
      {uid:"6621", label:"Service station attendants", group:"D"},
      {uid:"6622", label:"Store shelf stockers, clerks and order fillers", group:"D"},
      {uid:"6623", label:"Other sales related occupations", group:"D"},
      {uid:"6711", label:"Food counter attendants, kitchen helpers and related support occupations", group:"D"},
      {uid:"6721", label:"Support occupations in accommodation, travel and facilities set-up services", group:"D"},
      {uid:"6722", label:"Operators and attendants in amusement, recreation and sport", group:"D"},
      {uid:"6731", label:"Light duty cleaners", group:"D"},
      {uid:"6732", label:"Specialized cleaners", group:"D"},
      {uid:"6733", label:"Janitors, caretakers and building superintendents", group:"D"},
      {uid:"6741", label:"Dry cleaning, laundry and related occupations", group:"D"},
      {uid:"6742", label:"Other service support occupations, n.e.c.", group:"D"},
      {uid:"7201", label:"Contractors and supervisors, machining, metal forming, shaping and erecting trades and related occupations", group:"B"},
      {uid:"7202", label:"Contractors and supervisors, electrical trades and telecommunications occupations", group:"B"},
      {uid:"7203", label:"Contractors and supervisors, pipefitting trades", group:"B"},
      {uid:"7204", label:"Contractors and supervisors, carpentry trades", group:"B"},
      {uid:"7205", label:"Contractors and supervisors, other construction trades, installers, repairers and servicers", group:"B"},
      {uid:"7231", label:"Machinists and machining and tooling inspectors", group:"B"},
      {uid:"7232", label:"Tool and die makers", group:"B"},
      {uid:"7233", label:"Sheet metal workers", group:"B"},
      {uid:"7234", label:"Boilermakers", group:"B"},
      {uid:"7235", label:"Structural metal and platework fabricators and fitters", group:"B"},
      {uid:"7236", label:"Ironworkers", group:"B"},
      {uid:"7237", label:"Welders and related machine operators", group:"B"},
      {uid:"7241", label:"Electricians (except industrial and power system)", group:"B"},
      {uid:"7242", label:"Industrial electricians", group:"B"},
      {uid:"7243", label:"Power system electricians", group:"B"},
      {uid:"7244", label:"Electrical power line and cable workers", group:"B"},
      {uid:"7245", label:"Telecommunications line and cable workers", group:"B"},
      {uid:"7246", label:"Telecommunications installation and repair workers", group:"B"},
      {uid:"7247", label:"Cable television service and maintenance technicians", group:"B"},
      {uid:"7251", label:"Plumbers", group:"B"},
      {uid:"7252", label:"Steamfitters, pipefitters and sprinkler system installers", group:"B"},
      {uid:"7253", label:"Gas fitters", group:"B"},
      {uid:"7271", label:"Carpenters", group:"B"},
      {uid:"7272", label:"Cabinetmakers", group:"B"},
      {uid:"7281", label:"Bricklayers", group:"B"},
      {uid:"7282", label:"Concrete finishers", group:"B"},
      {uid:"7283", label:"Tilesetters", group:"B"},
      {uid:"7284", label:"Plasterers, drywall installers and finishers and lathers", group:"B"},
      {uid:"7291", label:"Roofers and shinglers", group:"B"},
      {uid:"7292", label:"Glaziers", group:"B"},
      {uid:"7293", label:"Insulators", group:"B"},
      {uid:"7294", label:"Painters and decorators (except interior decorators)", group:"B"},
      {uid:"7295", label:"Floor covering installers", group:"B"},
      {uid:"7301", label:"Contractors and supervisors, mechanic trades", group:"B"},
      {uid:"7302", label:"Contractors and supervisors, heavy equipment operator crews", group:"B"},
      {uid:"7303", label:"Supervisors, printing and related occupations", group:"B"},
      {uid:"7304", label:"Supervisors, railway transport operations", group:"B"},
      {uid:"7305", label:"Supervisors, motor transport and other ground transit operators", group:"B"},
      {uid:"7311", label:"Construction millwrights and industrial mechanics", group:"B"},
      {uid:"7312", label:"Heavy-duty equipment mechanics", group:"B"},
      {uid:"7313", label:"Heating, refrigeration and air conditioning mechanics", group:"B"},
      {uid:"7314", label:"Railway carmen/women", group:"B"},
      {uid:"7315", label:"Aircraft mechanics and aircraft inspectors", group:"B"},
      {uid:"7316", label:"Machine fitters", group:"B"},
      {uid:"7318", label:"Elevator constructors and mechanics", group:"B"},
      {uid:"7321", label:"Automotive service technicians, truck and bus mechanics and mechanical repairers", group:"B"},
      {uid:"7322", label:"Motor vehicle body repairers", group:"B"},
      {uid:"7331", label:"Oil and solid fuel heating mechanics", group:"B"},
      {uid:"7332", label:"Appliance servicers and repairers", group:"B"},
      {uid:"7333", label:"Electrical mechanics", group:"B"},
      {uid:"7334", label:"Motorcycle, all-terrain vehicle and other related mechanics", group:"B"},
      {uid:"7335", label:"Other small engine and small equipment repairers", group:"B"},
      {uid:"7361", label:"Railway and yard locomotive engineers", group:"B"},
      {uid:"7362", label:"Railway conductors and brakemen/women", group:"B"},
      {uid:"7371", label:"Crane operators", group:"B"},
      {uid:"7372", label:"Drillers and blasters - surface mining, quarrying and construction", group:"B"},
      {uid:"7373", label:"Water well drillers", group:"B"},
      {uid:"7381", label:"Printing press operators", group:"B"},
      {uid:"7384", label:"Other trades and related occupations, n.e.c.", group:"B"},
      {uid:"7441", label:"Residential and commercial installers and servicers", group:"C"},
      {uid:"7442", label:"Waterworks and gas maintenance workers", group:"C"},
      {uid:"7444", label:"Pest controllers and fumigators", group:"C"},
      {uid:"7445", label:"Other repairers and servicers", group:"C"},
      {uid:"7451", label:"Longshore workers", group:"C"},
      {uid:"7452", label:"Material handlers", group:"C"},
      {uid:"7511", label:"Transport truck drivers", group:"C"},
      {uid:"7512", label:"Bus drivers, subway operators and other transit operators", group:"C"},
      {uid:"7513", label:"Taxi and limousine drivers and chauffeurs", group:"C"},
      {uid:"7514", label:"Delivery and courier service drivers", group:"C"},
      {uid:"7521", label:"Heavy equipment operators (except crane)", group:"C"},
      {uid:"7522", label:"Public works maintenance equipment operators and related workers", group:"C"},
      {uid:"7531", label:"Railway yard and track maintenance workers", group:"C"},
      {uid:"7532", label:"Water transport deck and engine room crew", group:"C"},
      {uid:"7533", label:"Boat and cable ferry operators and related occupations", group:"C"},
      {uid:"7534", label:"Air transport ramp attendants", group:"C"},
      {uid:"7535", label:"Other automotive mechanical installers and servicers", group:"C"},
      {uid:"7611", label:"Construction trades helpers and labourers", group:"D"},
      {uid:"7612", label:"Other trades helpers and labourers", group:"D"},
      {uid:"7621", label:"Public works and maintenance labourers", group:"D"},
      {uid:"7622", label:"Railway and motor transport labourers", group:"D"},
      {uid:"8211", label:"Supervisors, logging and forestry", group:"B"},
      {uid:"8221", label:"Supervisors, mining and quarrying", group:"B"},
      {uid:"8222", label:"Contractors and supervisors, oil and gas drilling and services", group:"B"},
      {uid:"8231", label:"Underground production and development miners", group:"B"},
      {uid:"8232", label:"Oil and gas well drillers, servicers, testers and related workers", group:"B"},
      {uid:"8241", label:"Logging machinery operators", group:"B"},
      {uid:"8252", label:"Agricultural service contractors, farm supervisors and specialized livestock workers", group:"B"},
      {uid:"8255", label:"Contractors and supervisors, landscaping, grounds maintenance and horticulture services", group:"B"},
      {uid:"8261", label:"Fishing masters and officers", group:"B"},
      {uid:"8262", label:"Fishermen/women", group:"B"},
      {uid:"8411", label:"Underground mine service and support workers", group:"C"},
      {uid:"8412", label:"Oil and gas well drilling and related workers and services operators", group:"C"},
      {uid:"8421", label:"Chain saw and skidder operators", group:"C"},
      {uid:"8422", label:"Silviculture and forestry workers", group:"C"},
      {uid:"8431", label:"General farm workers", group:"C"},
      {uid:"8432", label:"Nursery and greenhouse workers", group:"C"},
      {uid:"8441", label:"Fishing vessel deckhands", group:"C"},
      {uid:"8442", label:"Trappers and hunters", group:"C"},
      {uid:"8611", label:"Harvesting labourers", group:"D"},
      {uid:"8612", label:"Landscaping and grounds maintenance labourers", group:"D"},
      {uid:"8613", label:"Aquaculture and marine harvest labourers", group:"D"},
      {uid:"8614", label:"Mine labourers", group:"D"},
      {uid:"8615", label:"Oil and gas drilling, servicing and related labourers", group:"D"},
      {uid:"8616", label:"Logging and forestry labourers", group:"D"},
      {uid:"9211", label:"Supervisors, mineral and metal processing", group:"B"},
      {uid:"9212", label:"Supervisors, petroleum, gas and chemical processing and utilities", group:"B"},
      {uid:"9213", label:"Supervisors, food and beverage processing", group:"B"},
      {uid:"9214", label:"Supervisors, plastic and rubber products manufacturing", group:"B"},
      {uid:"9215", label:"Supervisors, forest products processing", group:"B"},
      {uid:"9217", label:"Supervisors, textile, fabric, fur and leather products processing and manufacturing", group:"B"},
      {uid:"9221", label:"Supervisors, motor vehicle assembling", group:"B"},
      {uid:"9222", label:"Supervisors, electronics manufacturing", group:"B"},
      {uid:"9223", label:"Supervisors, electrical products manufacturing", group:"B"},
      {uid:"9224", label:"Supervisors, furniture and fixtures manufacturing", group:"B"},
      {uid:"9226", label:"Supervisors, other mechanical and metal products manufacturing", group:"B"},
      {uid:"9227", label:"Supervisors, other products manufacturing and assembly", group:"B"},
      {uid:"9231", label:"Central control and process operators, mineral and metal processing", group:"B"},
      {uid:"9232", label:"Central control and process operators, petroleum, gas and chemical processing", group:"B"},
      {uid:"9235", label:"Pulping, papermaking and coating control operators", group:"B"},
      {uid:"9241", label:"Power engineers and power systems operators", group:"B"},
      {uid:"9243", label:"Water and waste treatment plant operators", group:"B"},
      {uid:"9411", label:"Machine operators, mineral and metal processing", group:"C"},
      {uid:"9412", label:"Foundry workers", group:"C"},
      {uid:"9413", label:"Glass forming and finishing machine operators and glass cutters", group:"C"},
      {uid:"9414", label:"Concrete, clay and stone forming operators", group:"C"},
      {uid:"9415", label:"Inspectors and testers, mineral and metal processing", group:"C"},
      {uid:"9416", label:"Metalworking and forging machine operators", group:"C"},
      {uid:"9417", label:"Machining tool operators", group:"C"},
      {uid:"9418", label:"Other metal products machine operators", group:"C"},
      {uid:"9421", label:"Chemical plant machine operators", group:"C"},
      {uid:"9422", label:"Plastics processing machine operators", group:"C"},
      {uid:"9423", label:"Rubber processing machine operators and related workers", group:"C"},
      {uid:"9431", label:"Sawmill machine operators", group:"C"},
      {uid:"9432", label:"Pulp mill machine operators", group:"C"},
      {uid:"9433", label:"Papermaking and finishing machine operators", group:"C"},
      {uid:"9434", label:"Other wood processing machine operators", group:"C"},
      {uid:"9435", label:"Paper converting machine operators", group:"C"},
      {uid:"9436", label:"Lumber graders and other wood processing inspectors and graders", group:"C"},
      {uid:"9437", label:"Woodworking machine operators", group:"C"},
      {uid:"9441", label:"Textile fibre and yarn, hide and pelt processing machine operators and workers", group:"C"},
      {uid:"9442", label:"Weavers, knitters and other fabric making occupations", group:"C"},
      {uid:"9445", label:"Fabric, fur and leather cutters", group:"C"},
      {uid:"9446", label:"Industrial sewing machine operators", group:"C"},
      {uid:"9447", label:"Inspectors and graders, textile, fabric, fur and leather products manufacturing", group:"C"},
      {uid:"9461", label:"Process control and machine operators, food and beverage processing", group:"C"},
      {uid:"9462", label:"Industrial butchers and meat cutters, poultry preparers and related workers", group:"C"},
      {uid:"9463", label:"Fish and seafood plant workers", group:"C"},
      {uid:"9465", label:"Testers and graders, food and beverage processing", group:"C"},
      {uid:"9471", label:"Plateless printing equipment operators", group:"C"},
      {uid:"9472", label:"Camera, platemaking and other prepress occupations", group:"C"},
      {uid:"9473", label:"Binding and finishing machine operators", group:"C"},
      {uid:"9474", label:"Photographic and film processors", group:"C"},
      {uid:"9521", label:"Aircraft assemblers and aircraft assembly inspectors", group:"C"},
      {uid:"9522", label:"Motor vehicle assemblers, inspectors and testers", group:"C"},
      {uid:"9523", label:"Electronics assemblers, fabricators, inspectors and testers", group:"C"},
      {uid:"9524", label:"Assemblers and inspectors, electrical appliance, apparatus and equipment manufacturing", group:"C"},
      {uid:"9525", label:"Assemblers, fabricators and inspectors, industrial electrical motors and transformers", group:"C"},
      {uid:"9526", label:"Mechanical assemblers and inspectors", group:"C"},
      {uid:"9527", label:"Machine operators and inspectors, electrical apparatus manufacturing", group:"C"},
      {uid:"9531", label:"Boat assemblers and inspectors", group:"C"},
      {uid:"9532", label:"Furniture and fixture assemblers and inspectors", group:"C"},
      {uid:"9533", label:"Other wood products assemblers and inspectors", group:"C"},
      {uid:"9534", label:"Furniture finishers and refinishers", group:"C"},
      {uid:"9535", label:"Plastic products assemblers, finishers and inspectors", group:"C"},
      {uid:"9536", label:"Industrial painters, coaters and metal finishing process operators", group:"C"},
      {uid:"9537", label:"Other products assemblers, finishers and inspectors", group:"C"},
      {uid:"9611", label:"Labourers in mineral and metal processing", group:"D"},
      {uid:"9612", label:"Labourers in metal fabrication", group:"D"},
      {uid:"9613", label:"Labourers in chemical products processing and utilities", group:"D"},
      {uid:"9614", label:"Labourers in wood, pulp and paper processing", group:"D"},
      {uid:"9615", label:"Labourers in rubber and plastic products manufacturing", group:"D"},
      {uid:"9616", label:"Labourers in textile processing", group:"D"},
      {uid:"9617", label:"Labourers in food and beverage processing", group:"D"},
      {uid:"9618", label:"Labourers in fish and seafood processing", group:"D"},
      {uid:"9619", label:"Other labourers in processing, manufacturing and utilities", group:"D"},
    ];
    return options;
  }

  getPeriodOfTimeOptions(): Array<ICatalogItem> {
    const options = [
      {uid:'6m', label: '6 months minimum'},
      {uid:'1y', label: '1 year'},
      {uid:'1y+', label: 'more than 1 year'},
    ];
    return options;
  }

  getCanadaCities(): Array<ICatalogItem> {
    const options = [
      {uid:'airdrie', label:'Airdrie', group:'Alberta'},
      {uid:'brooks', label:'Brooks', group:'Alberta'},
      {uid:'calgary', label:'Calgary', group:'Alberta'},
      {uid:'camrose', label:'Camrose', group:'Alberta'},
      {uid:'coldLake', label:'Cold Lake', group:'Alberta'},
      {uid:'edmonton', label:'Edmonton', group:'Alberta'},
      {uid:'fortSaskatchewan', label:'Fort Saskatchewan', group:'Alberta'},
      {uid:'grandePrairie', label:'Grande Prairie', group:'Alberta'},
      {uid:'leduc', label:'Leduc', group:'Alberta'},
      {uid:'lethbridge', label:'Lethbridge', group:'Alberta'},
      {uid:'lloydminster', label:'Lloydminster', group:'Alberta'},
      {uid:'medicineHat', label:'Medicine Hat', group:'Alberta'},
      {uid:'redDeer', label:'Red Deer', group:'Alberta'},
      {uid:'spruceGrove', label:'Spruce Grove', group:'Alberta'},
      {uid:'saintAlbert', label:'Saint Albert', group:'Alberta'},
      {uid:'wetaskiwin', label:'Wetaskiwin', group:'Alberta'},
    ];
    return options;
  }

  getDegreesOfStudy(): Array<ICatalogItem> {
    const options = [
      {uid:'highschool', label: 'high school degree'},
      {uid:'college', label: 'college degree'},
      {uid:'master', label: "master's degree"},
      {uid:'doctoral', label: "doctoral degree"},
      {uid:'postgraduate', label: "postgraduate degree"},
    ];
    return options;
  }

  getWorkShiftOptions(): Array<ICatalogItem> {
    const options = [
      {uid:'part-time', label: 'Part-Time Job'},
      {uid:'full', label: 'Full-Time Job'},
    ];
    return options;
  }

  getLanguages(): Observable<Array<ILanguage>> {
    const route = `${host}/languages`;
    return this.http.get<Array<ILanguage>>(route);
  }

  getProvinces(): Observable<Array<ISimpleCatalog>> {
    const route = `${host}/provinces`;
    return this.http.get<Array<ISimpleCatalog>>(route);
  }

  getRegionalDistricts(): Observable<Array<ISimpleCatalog>> {
    const route = `${host}/regionalDistricts`;
    return this.http.get<Array<ISimpleCatalog>>(route);
  }

  getCities(): Observable<Array<ICity>> {
    const route = `${host}/cities`;
    return this.http.get<Array<ICity>>(route);
  }

  getSchoolLevels(): Observable<Array<ISchoolLevel>> {
    const route = `${host}/schoolLevels`;
    return this.http.get<Array<ISchoolLevel>>(route);
  }

  getOccupations(): Observable<Array<IOccupation>> {
    const route = `${host}/occupations`;
    return this.http.get<Array<IOccupation>>(route);
  }

  getSectors(): Observable<Array<ICatalogItem>> {
    const route = `${host}/sectors`;
    return this.http.get<Array<ICatalogItem>>(route);
  }

  getSchools(): Observable<Array<ICatalogItem>> {
    const route = `${host}/schools`;
    return this.http.get<Array<ICatalogItem>>(route);
  }

  getWorkPermits(): Observable<Array<ICatalogItem>> {
    const route = `${host}/workPermits`;
    return this.http.get<Array<ICatalogItem>>(route);
  }

  getRelationships(): Observable<Array<ICatalogItem>> {
    const route = `${host}/relationships`;
    return this.http.get<any>(route);
  }

  saveApplication(data:IDataUser): Observable<IDataUser> {
    const route = `${host}/saveApplication`;
    return this.http.post<IDataUser>(route,data);
  }

  createStripe(email:string, application:string) : Observable<IDataUser> {
    const route = `${host}/createStripe`;
    const body = {email, application}
    return this.http.post<IDataUser>(route,body);
  }

  getUserReport(uid: string): Observable<any> {
    const route = `${host}/report?uid=${uid}`;
    return this.http.get<any>(route);
  }

  getFileSource(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }

  getPageSections(): Observable<Array<any>> {
    const route = `${host}/pageSections`;
    return this.http.get<Array<any>>(route);
  }

  getChannels(): Observable<Array<IChannel>> {
    const route = `${host}/channels`;
    return this.http.get<Array<any>>(route);
  }

}

/// Cuenta nivel 4 con un limite de 190,000 mensual.

/// Ingresos Esporadicos -> (Regimen tributario - actividad empresarial y profecional).
/// Pagar la parte de IVA y ISR - Cambiar 
    // aumentar regimen fiscal