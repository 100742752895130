import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICatalogItem, ICity, IEducationLite, IFourSkills, IHasEnglishLevel, IHasFrenchLevel, IHasFriendsInCanada, IHasRelativesInCanada, IIsLivingInCanadaLite, IJobOfferLite, IOccupation, IOtherRank, IPartner, ISchoolLevel, ISimpleCatalog, IWorkingInCanadaLite, IWorkPermit } from '../models';
import { get as _get } from 'lodash';
import { CatalogService } from '../catalog.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  @Output() onSubmitClicked: EventEmitter<IPartner> = new EventEmitter();

  // Catalog Options
  public englishCertifications: ICatalogItem[];
  public frenchCertifications: ICatalogItem[];
  public otherLanguageLevels: ICatalogItem[];
  public genderOptions: Array<ICatalogItem>;
  public provinces: ISimpleCatalog[];
  public cities: ICity[];
  public regionalDistricts: ISimpleCatalog[];
  public schoolLeves: ISchoolLevel[];
  public sectors: ICatalogItem[];
  public occupations: IOccupation[];
  public workPermits: ICatalogItem[];
  public skillScores: ICatalogItem[];

  public name: string;
  public gender: string;
  public hasEnglishCertificate: boolean = false;
  public hasFrenchCertificate: boolean = false;
  public englishCertification: ICatalogItem;
  public frenchCertification: ICatalogItem;
  public customLevelEnglish: ICatalogItem;
  public customLevelFrench: ICatalogItem;

  public userEnglishFourSkills: IFourSkills;
  public userFrenchFourSkills: IFourSkills;
  public userDuolingoPoints: number = 0;

  public isLivingInCanada: boolean = false;
  public livingInRuralArea: boolean = false;
  public monthsLivingInCanda: number;
  public userLivingProvince: ISimpleCatalog;
  public userLivingRegionalDistrict: ISimpleCatalog;
  public userLivingCity: ICity;

  public isUserVolunteer: boolean;
  public userRuralAreaCity: ICity;
  public monthsLivingInRuralArea: number;
  public userColdClimateCity: ICity;
  public livingInColdClimate: boolean = false;
  public monthsLivingInColdClimate: number;

  public hasCanadaStudies: boolean = false;
  public educationSector: ICatalogItem;
  public educationProvince: ISimpleCatalog;
  public educationCity: ICity;
  public monthsEducationTime: number;

  public hasAnOffer: boolean = false;
  public cityJobOffer: ICity;
  public jobOfferOccupation: IOccupation;
  public hasACanadianWorkPermit: boolean;
  public workPermit: ICatalogItem;
  public workPermitRemainingMonths: number;
  public isWorkingInCanada: boolean;
  public provinceWork: ISimpleCatalog;
  public regionalDistrictWork: ISimpleCatalog;
  public cityWork: ICity;
  public sectorWork: ICatalogItem;
  public workOccupation: IOccupation;
  public monthsOfExperience: number;

  public directRelativesInCanada: number;
  public friendsInCanada: number;

  public numbersOptions: ICatalogItem[];

  public lastDegreeOfStudies: ISchoolLevel;

  public otherEnglishCertificateName: string;
  public otherFrenchCertificateName: string;

  public wasSubmitClicked: boolean;

  public relativesData:IHasRelativesInCanada;
  public friendsData: IHasFriendsInCanada;

  constructor(public catalogService: CatalogService) { }

  ngOnInit(): void {
    this.loadCatalogsOptions();  
  }

  loadCatalogsOptions() {
    this.englishCertifications = this.catalogService.getEnglishCertificationTypes();
    this.frenchCertifications = this.catalogService.getFrenchCertificationTypes();
    this.genderOptions = [
      {label: 'Male', uid:'male'},
      {label: 'Female', uid:'female'},
    ]
    this.otherLanguageLevels = this.catalogService.getOtherLanguageLevels();
    this.catalogService.getCities().subscribe(cities => {
      this.cities =  cities ? cities : [];
    });
    this.catalogService.getProvinces().subscribe(provinces => {
      this.provinces =  provinces ? provinces : [];
    });
    this.catalogService.getRegionalDistricts().subscribe(districts => {
      this.regionalDistricts =  districts ? districts : [];
    });
    this.catalogService.getSchoolLevels().subscribe(levels => {
      this.schoolLeves =  levels ? levels : [];
    });
    this.catalogService.getSectors().subscribe(sectors => {
      this.sectors =  sectors ? sectors : [];
    });
    this.catalogService.getOccupations().subscribe(occupations => {
      this.occupations =  occupations ? occupations : [];
    });
    this.catalogService.getWorkPermits().subscribe(workPermits => {
      this.workPermits =  workPermits ? workPermits : [];
    });
    this.numbersOptions = this.catalogService.getNumbersOptions();
    this.skillScores = this.catalogService.getSkillScore();
  }

  validForm() {}

  onGenderChange(data:ICatalogItem) {
    this.gender = _get(data,'uid');
  }

  switchEnglishCertification() {
    this.hasEnglishCertificate = !this.hasEnglishCertificate;
    if(!this.hasEnglishCertificate) {
      this.englishCertification = null;
      this.englishCertification
    }
  }

  switchFrenchCertification() {
    this.hasFrenchCertificate = !this.hasFrenchCertificate;
    if(!this.hasFrenchCertificate) {
      this.frenchCertification = null;
    }
  }

  switchLivingInCanada() {
    this.isLivingInCanada = !this.isLivingInCanada;
  }

  switchUserVolunteer() {
    this.isUserVolunteer = !this.isUserVolunteer;
    this.validForm();
  }

  switchLivingInRuralArea() {
    this.livingInRuralArea = !this.livingInRuralArea;
    this.validForm();
  }

  switchLivingInColdClimate() {
    this.livingInColdClimate = !this.livingInColdClimate;
    this.validForm();
  }

  switchHasCanadaStudies() {
    this.hasCanadaStudies = !this.hasCanadaStudies;
  }

  switchJobOffer() {
    this.hasAnOffer = !this.hasAnOffer;
  }

  switchHasACanadianWorkPermit() {
    this.hasACanadianWorkPermit = !this.hasACanadianWorkPermit;
  }

  onProvinceChange(data: ISimpleCatalog) {
    this.userLivingProvince = data;
    this.userLivingRegionalDistrict = null;
    this.userLivingCity = null;    
  }

  onEnglishCertificationChange(option:any) {
    this.englishCertification = option;
    if(option.uid === 'Other' && this.otherLanguageLevels[0]) {
      this.customLevelEnglish = this.otherLanguageLevels[0];
    }
  }

  onFrenchCertificationChange(option:any) {
    this.frenchCertification = option;
    if(option.uid === 'Other' && this.otherLanguageLevels[0]) {
      this.customLevelFrench = this.otherLanguageLevels[0];
    }
  }

  onRegionalDistrictChange(data: ISimpleCatalog) {
    this.userLivingRegionalDistrict = data;
  }

  onChangeUserEnglishForSkills(data: IFourSkills) {
    this.userEnglishFourSkills = data;
  }

  onChangeUserFrenchForSkills(data: IFourSkills) {
    this.userFrenchFourSkills = data;
  }

  onCityChange(data: ICity) {
    this.userLivingCity = data;
  }

  onRuralAreaCityChange(data: ICity) {
    this.userRuralAreaCity = data;
  }

  onColdClimateCityChange(data: ICity) {
    this.userColdClimateCity = data;
  }

  onLastDegreeChange(option:any) {
    this.lastDegreeOfStudies = option;
  }

  onEducationSectorChange(data: ICatalogItem) {
    this.educationSector = data;
  }

  onEducationProvinceChange(data: ISimpleCatalog) {
    this.educationProvince = data;
  }

  onEducationCityChange(data: ICity) {
    this.educationCity = data;
  }

  onCityJobOfferChange(data: ICity) {
    this.cityJobOffer = data;
  }

  onJobOfferOccupationChange(data: IOccupation) {
    this.jobOfferOccupation = data;
  }

  onWorkPermitChange(data: ICatalogItem) {
    this.workPermit = data;
  }

  switchWorkingInCanada() {
    this.isWorkingInCanada = !this.isWorkingInCanada;
  }

  onProvinceWorkChange(data: ISimpleCatalog) {
    this.provinceWork = data;
  }

  onRegionalDistrictWorkChange(data: ISimpleCatalog) {
    this.regionalDistrictWork = data;
  }

  onCityWorkChange(data: ICity) {
    this.cityWork = data;
  }

  onSectorWork(data: ICatalogItem) {
    this.sectorWork = data;
  }

  onWorkOccupationChange(data: IOccupation) {
    this.workOccupation = data;
  }

  onDirectRelativesChange(option:any) {
    const value = option && option.uid ? option.uid : 0;
    this.directRelativesInCanada = value ;
  }

  onSubmitForm() {
    this.validForm();
    this.loadData();
  }

  loadData() {
    const dataPartner: IPartner= {
      name: this.name || '',
      gender: this.gender || '',
      hasEnglishLevel: this.getEnglishData(),
      hasFrenchLevel: this.getFrenchData(),
      isLivingInCanada: this.getLivingData(),
      education: this.getEducationData(),
      hasJobOffer: this.getJobOfferData(),
      workPermit: this.getWorkPermit(),
      isWorkingInCanada: this.getWorkingInCanadaData(),
    };
    this.onSubmitClicked.next(dataPartner);
  }

  getEnglishData(): IHasEnglishLevel {
    let validEnglishPart = true;
    let englishData: IHasEnglishLevel;
    if(this.hasEnglishCertificate) {
      validEnglishPart = false;
      const certificationType = _get(this.englishCertification,'uid') || '';
      switch(certificationType) {
        case 'IELTS': 
          if(this.userEnglishFourSkills) {
            englishData = {
              status: true,
              ielts: this.userEnglishFourSkills,
            }
            validEnglishPart = true;
          }
          break;
        case 'TOEFL':
          if(this.userEnglishFourSkills) {
            englishData = {
              status: true,
              toefl: this.userEnglishFourSkills,
            }
            validEnglishPart = true;
          }
          break;
        case 'Duolingo': 
          if(this.userDuolingoPoints >= 0) {
            const duolingoPoints = {points: this.userDuolingoPoints}
            englishData = {
              status: true,
              duolingo: duolingoPoints,
            }
            validEnglishPart = true;
          }
          break;
        case 'Other':
          if(this.customLevelEnglish) {
            const otherLevel = _get(this.customLevelEnglish,'uid');
            const englishCertificate: IOtherRank = 
            {level: otherLevel, name:this.otherEnglishCertificateName || ''}
            englishData = {
              status: true,
              other: englishCertificate,
            }
            validEnglishPart = true;
          }
          break;
        default:
          englishData = {
            status: false,
          }
          break;
      }
    } else {
      englishData = {
        status: false,
      }
    }
    return englishData;
  }

  getFrenchData(): IHasFrenchLevel {
    let validFrenchPart = true;
    let frenchData: IHasFrenchLevel;
    if(this.hasFrenchCertificate) {
      validFrenchPart = false;
      const certificationType = _get(this.frenchCertification,'uid') || '';
      switch(certificationType) {
        case 'TEF': 
          if(this.userFrenchFourSkills) {
            frenchData = {
              status: true,
              tef: this.userFrenchFourSkills,
            }
            validFrenchPart = true;
          }
          break;
        case 'TCF':
          if(this.userFrenchFourSkills) {
            frenchData = {
              status: true,
              tcf: this.userFrenchFourSkills,
            }
            validFrenchPart = true;
          }
          break;
        case 'Other':
          if(this.customLevelFrench) {
            const otherLevel = _get(this.customLevelFrench,'uid');
            const frenchCertificate:IOtherRank = 
            {level: otherLevel, name:this.otherFrenchCertificateName || ''}
            frenchData = {
              status: true,
              other: frenchCertificate,
            }
            validFrenchPart = true;
          }
          break;
        default:
          frenchData = {
            status: false,
          }
          break;
      } 
    } else {
      frenchData = {
        status: false,
    }
    
    }
    return frenchData;
  }

  getLivingData(): IIsLivingInCanadaLite {
    const data: IIsLivingInCanadaLite = {
      status: this.isLivingInCanada ? this.isLivingInCanada : false,
      city: _get(this.userLivingCity,'name') ?  _get(this.userLivingCity,'name') : null,
      months: this.monthsLivingInCanda ? this.monthsLivingInCanda : 0,
      regionalDistrict: _get(this.userLivingRegionalDistrict,'value') ?  _get(this.userLivingRegionalDistrict,'value') : null,
      volunteer: this.isUserVolunteer ? this.isUserVolunteer : false,
      livingInRuralArea: {
        status: this.livingInRuralArea ? this.livingInRuralArea : false,
        city:  _get(this.userRuralAreaCity ,'name') ? _get(this.userRuralAreaCity ,'name') : null,
        months: this.monthsLivingInRuralArea ? this.monthsLivingInRuralArea : 0
      },
      livingInColdClimate: {
        status: this.livingInColdClimate ? this.livingInColdClimate : false,
        city:  _get(this.userColdClimateCity ,'name') ? _get(this.userColdClimateCity ,'name') : null,
        months: this.monthsLivingInColdClimate ? this.monthsLivingInColdClimate : 0,
      },
    }
    return data;
  }

  getEducationData(): IEducationLite {
    const educationData: IEducationLite = {
      schoolLevel: {
        label: _get(this.lastDegreeOfStudies,'label') || '',
        order: _get(this.lastDegreeOfStudies,'order') || 0,
        uid: _get(this.lastDegreeOfStudies,'uid') || '',
      },
      sector: _get(this.educationSector,'label') || '',
      province: _get(this.educationProvince,'value') || '',
      city: _get(this.educationCity,'name') || '',
      timeInMonths: this.monthsEducationTime || 0,
    }
    return educationData;
  }

  getJobOfferData(): IJobOfferLite {
    const hasJobOffer: IJobOfferLite = {
      status: this.hasAnOffer ? this.hasAnOffer : false,
      city: _get(this.cityJobOffer,'name') || '',
      occupation: {
        uid: _get(this.jobOfferOccupation,'uid') || '',
        description: _get(this.jobOfferOccupation,'label') || '',
        type: _get(this.jobOfferOccupation,'type') || '',
      },
    }
    return hasJobOffer;
  }

  getWorkPermit(): IWorkPermit {
    const workPermit: IWorkPermit = {
      uid: _get(this.workPermit,'uid') || '',
      label: _get(this.workPermit,'label') || '',
      remainingMonths: this.workPermitRemainingMonths || 0,
    }
    return workPermit;
  }

  getWorkingInCanadaData(): IWorkingInCanadaLite {
    const workingInCanada: IWorkingInCanadaLite = {
      status: this.isWorkingInCanada ? this.isWorkingInCanada : false,
      province: _get(this.provinceWork,'value') || '',
      regionalDistrict: _get(this.regionalDistrictWork,'value') || '',
      sector: _get(this.sectorWork,'label') || '',
      occupation: {
        uid: _get(this.workOccupation,'uid') || '',
        description: _get(this.workOccupation,'label') || '',
        type: _get(this.workOccupation,'type') || '',
      },
      monthsOfExperience: this.monthsOfExperience || 0,
    }
    return workingInCanada;
  }

}
