<div class="one-columns">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="name" (change)="validForm()" type="type" placeholder="Name">
            <mat-hint *ngIf="!name && wasSubmitClicked" class="errorForm">
                Amount required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="one-columns">
    <app-select [label]="'Gender'" [options]="genderOptions" (onValueChange)="onGenderChange($event)"></app-select>
</div>
<h3 class="section-title"><mat-icon class="icon-title">translate</mat-icon> PARTNER LANGUAGE LEVEL</h3>
<mat-divider class="section-space"></mat-divider>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchEnglishCertification()">Does your partner have any certification in English?</mat-slide-toggle>
</div>
<div class="two-columns" *ngIf="hasEnglishCertificate">
    <div class="label">
        <div class="one-columns">
            <app-select [label]="'Type of certification'" [options]="englishCertifications" (onValueChange)="onEnglishCertificationChange($event)"></app-select>
        </div>
    </div>
    <div class="label" *ngIf="englishCertification && englishCertification.uid === 'Other'">
        <mat-form-field appearance="outline">
            <mat-label>Which?</mat-label>
            <input matInput [(ngModel)]="otherEnglishCertificateName" type="text">
        </mat-form-field>
    </div>
    <div class="label" *ngIf="englishCertification && englishCertification.uid === 'Duolingo'">
        <mat-form-field appearance="outline">
            <mat-label>Duolingo Poins</mat-label>
            <input matInput [(ngModel)]="userDuolingoPoints" type="number">
        </mat-form-field>
    </div>
</div>
<div class="one-columns" *ngIf="hasEnglishCertificate && englishCertification && englishCertification.uid === 'IELTS' ">
    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserEnglishForSkills($event)"></app-four-skills>
</div>
<div class="one-columns" *ngIf="hasEnglishCertificate && englishCertification && englishCertification.uid === 'TOEFL' ">
    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserEnglishForSkills($event)"></app-four-skills>
</div>
<div class="one-columns" *ngIf="englishCertification && englishCertification.uid === 'Other'">
    <app-select [label]="'Level'" [options]="otherLanguageLevels" [default]="customLevelEnglish" (onValueChange)="onCustomLevelEnglishChange($event)"></app-select>
</div>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchFrenchCertification()">Does your partner have any certification in French?</mat-slide-toggle>
</div>
<div class="two-columns" *ngIf="hasFrenchCertificate">
    <div class="label">
        <div class="one-columns">
            <app-select [label]="'Type of certification'" [options]="frenchCertifications" (onValueChange)="onFrenchCertificationChange($event)"></app-select>
        </div>
    </div>
    <div class="label" *ngIf="frenchCertification && frenchCertification.uid === 'Other'">
        <mat-form-field appearance="outline">
            <mat-label>Which?</mat-label>
            <input matInput [(ngModel)]="otherFrenchCertificateName" type="text">
        </mat-form-field>
    </div>
    <div class="label" *ngIf="frenchCertification && frenchCertification.uid === 'Duolingo'">
        <mat-form-field appearance="outline">
            <mat-label>Duolingo Poins</mat-label>
            <input matInput [(ngModel)]="userDuolingoFrenchPoints" type="number">
        </mat-form-field>
    </div>
</div>
<div class="one-columns" *ngIf="hasFrenchCertificate && frenchCertification && frenchCertification.uid === 'TEF'">
    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserFrenchForSkills($event)"></app-four-skills>
</div>
<div class="one-columns" *ngIf="hasFrenchCertificate && frenchCertification && frenchCertification.uid === 'TCF'">
    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserFrenchForSkills($event)"></app-four-skills>
</div>
<div class="one-columns" *ngIf="frenchCertification && frenchCertification.uid === 'Other'">
    <app-select [label]="'Level'" [options]="otherLanguageLevels" [default]="customLevelFrench" (onValueChange)="onCustomLevelFrenchChange($event)"></app-select>
</div>
<h3 class="section-title"><mat-icon class="icon-title">house</mat-icon> PARTNER Residence </h3>
<mat-divider class="section-space"></mat-divider>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchLivingInCanada()">
        Is your partner currently living in Canada?
    </mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="isLivingInCanada">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>How many months has your partner been living in Canada?</mat-label>
            <input matInput [(ngModel)]="monthsLivingInCanda" (change)="validForm()" type="number" placeholder="Months living in Canada">
            <mat-hint *ngIf="!monthsLivingInCanda && wasSubmitClicked && isLivingInCanada" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="one-columns" *ngIf="isLivingInCanada">
    <app-select [label]="'In which province does your partner live?'" [options]="provinces" (onValueChange)="onProvinceChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isLivingInCanada && userLivingProvince && userLivingProvince.value == 'British Columbia'">
    <app-select [label]="'In which district does your partner live?'" [options]="regionalDistricts" [default]="userLivingRegionalDistrict" (onValueChange)="onRegionalDistrictChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isLivingInCanada">
    <app-select [label]="'In which city does your partner live?'" [options]="cities" [default]="userLivingCity" (onValueChange)="onCityChange($event)"></app-select>
</div>
<div class="one-columns radio-question" *ngIf="isLivingInCanada">
    <mat-slide-toggle (change)="switchUserVolunteer()">
        Is your partner a volunteer in a Canadian program?
    </mat-slide-toggle>
</div>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchLivingInRuralArea()">
        Has your partner lived or lives in a rural area?
    </mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="livingInRuralArea">
    <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onRuralAreaCityChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="livingInRuralArea">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Months living there?</mat-label>
            <input matInput [(ngModel)]="monthsLivingInRuralArea" (change)="validForm()" type="number" placeholder="Months living there">
            <mat-hint *ngIf="!monthsLivingInRuralArea && wasSubmitClicked" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchLivingInColdClimate()">
        Has your partner lived or do they live in a Canadian cold climate area?
    </mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="livingInColdClimate">
    <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onColdClimateCityChange($event)"></app-select>
</div>
<div class="one-columns"  *ngIf="livingInColdClimate">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Months living there?</mat-label>
            <input matInput [(ngModel)]="monthsLivingInColdClimate" (change)="validForm()" type="number" placeholder="Months living there">
            <mat-hint *ngIf="!monthsLivingInColdClimate && wasSubmitClicked" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>

<h3 class="section-title"><mat-icon class="icon-title">school</mat-icon> PARTNER Education </h3>
<mat-divider class="section-space"></mat-divider>
<div class="one-columns">
    <app-select [label]="'What is the last degree of studies of your partner?'" [options]="schoolLeves" (onValueChange)="onLastDegreeChange($event)"></app-select>
</div>
<div class="one-columns">
    <app-select [label]="'Sector'" [options]="sectors" (onValueChange)="onEducationSectorChange($event)"></app-select>
</div>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchHasCanadaStudies()">Did your partner study in Canada?</mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="hasCanadaStudies">
    <app-select [label]="'In which province did your partner study?'" [options]="provinces" (onValueChange)="onEducationProvinceChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="hasCanadaStudies">
    <app-select [label]="'In which city did your partner study?'" [options]="cities" (onValueChange)="onEducationCityChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="hasCanadaStudies">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Months studing in Canada?</mat-label>
            <input matInput [(ngModel)]="monthsEducationTime" (change)="validForm()" type="number" placeholder="Months studing there">
            <mat-hint *ngIf="!monthsEducationTime && wasSubmitClicked" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<h3 class="section-title"><mat-icon class="icon-title">work</mat-icon> PARTNER Job Offer</h3>
<mat-divider class="section-space"></mat-divider>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchJobOffer()">Does your partner have a Canadian job offer?</mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="hasAnOffer">
    <app-select [label]="'In which city does your partner have the job offer?'" [options]="cities" (onValueChange)="onCityJobOfferChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="hasAnOffer">
    <app-autocomplete
    [label]="'What is the occupation of the job offer?'"
    [placeholder]="'Pick one'"
    [options]="occupations"
    (onValueChange)="onJobOfferOccupationChange($event)">
    </app-autocomplete>
</div>
<h3 class="section-title"><mat-icon class="icon-title">apartment</mat-icon> PARTNER Work Experience</h3>
<mat-divider class="section-space"></mat-divider>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchHasACanadianWorkPermit()">Does your partner have a job offer?</mat-slide-toggle>
</div>
<div class="one-column" *ngIf="hasACanadianWorkPermit">
    <app-select [label]="'Your partners work permit'" [options]="workPermits" (onValueChange)="onWorkPermitChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="hasACanadianWorkPermit">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Remaining months</mat-label>
            <input matInput [(ngModel)]="workPermitRemainingMonths" (change)="validForm()" type="number" placeholder="Months">
            <mat-hint *ngIf="!workPermitRemainingMonths && wasSubmitClicked" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="one-columns radio-question">
    <mat-slide-toggle (change)="switchWorkingInCanada()">Is your partner currently working in Canada?</mat-slide-toggle>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada">
    <app-select [label]="'in which province does your partner work?'" [options]="provinces" (onValueChange)="onProvinceWorkChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada && provinceWork && provinceWork.value == 'British Columbia'">
    <app-select [label]="'in which regional district does your partner work?'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictWorkChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada">
    <app-select [label]="'in which city does your partner work?'" [options]="cities" (onValueChange)="onCityWorkChange($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada">
    <app-select [label]="'Sector'" [options]="sectors" (onValueChange)="onSectorWork($event)"></app-select>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada">
    <app-autocomplete
    [label]="'What is your partners occupation?'"
    [placeholder]="'Pick one'"
    [options]="occupations"
    (onValueChange)="onWorkOccupationChange($event)">
    </app-autocomplete>
</div>
<div class="one-columns" *ngIf="isWorkingInCanada">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>Months of experience</mat-label>
            <input matInput [(ngModel)]="monthsOfExperience" (change)="validForm()" type="number" placeholder="Months">
            <mat-hint *ngIf="!monthsOfExperience && wasSubmitClicked" class="errorForm">
                Months required
            </mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="one-columns">
    <button class="action-button" mat-button matStepperNext (click)="onSubmitForm()">Next</button>
</div>