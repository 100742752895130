<div class="container">
    <div class="elements">
        <div class="logo" [routerLink]="['/home']">
            <img src="/assets/img/ICANlogo.png" alt="">
        </div>
        <div class="menu">
            <div class="option link-label" [routerLink]="['/home', 'aboutUs']">About us</div>
            <div class="option link-label" [routerLink]="['/home', 'benefits']">Benefits</div>
            <div class="option link-label" [routerLink]="['/home', 'howItWorks']">How it works?</div>
            <div class="option link-label" routerLink="/form">Apply</div>

            <div class="option hamburguer">
                <button mat-icon-button class="menu-button" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>menu</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button [routerLink]="['/home', 'aboutUs']" mat-menu-item>
                      <mat-icon>info</mat-icon>
                      <span>About us</span>
                    </button>
                    <button [routerLink]="['/home', 'benefits']" mat-menu-item>
                      <mat-icon>thumb_up</mat-icon>
                      <span>Benefits</span>
                    </button>
                    <button [routerLink]="['/home', 'howItWorks']" mat-menu-item>
                      <mat-icon>settings</mat-icon>
                      <span>How it works?</span>
                    </button>
                    <button [routerLink]="['/form']" mat-menu-item>
                      <mat-icon>check_box</mat-icon>
                      <span>Apply</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

