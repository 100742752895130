import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-viewer-full-complete',
  templateUrl: './pdf-viewer-full-complete.component.html',
  styleUrls: ['./pdf-viewer-full-complete.component.scss']
})
export class PdfViewerFullCompleteComponent implements OnInit {
  public currentPage: number = 1;
  public pdfSrc: string;
  public totalPages: number;
  public scale: number = 1;
  public rotationAmount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PdfViewerFullCompleteComponent>
  ) {}

  ngOnInit(): void {
    this.pdfSrc = this.data.pdfSrc;
    this.currentPage = this.data.currentIndex || 1;
  }

  zoomIn() {
    if (this.scale < 3) {
      this.scale = this.scale + 0.5;
    }
  }

  zoomOut() {
    if (this.scale > 0) {
      this.scale = this.scale - 0.5;
    }
  }

  rotate() {
    if (this.rotationAmount < 360) {
      this.rotationAmount = this.rotationAmount + 90;
    } else {
      this.rotationAmount = 90;
    }
  }

  fileLoaded(data: any) {
    if (data && data._pdfInfo) {
      this.totalPages = data._pdfInfo.numPages;
    }
  }

  download() {
    window.open(this.pdfSrc);
  }

  preview() {
    if (this.totalPages > 1) {
      if (1 < this.currentPage) {
        this.currentPage = this.currentPage - 1;
      } else {
        this.currentPage = this.totalPages;
      }
    }
  }

  next() {
    if (this.totalPages > 1) {
      const newIndex = this.currentPage + 1;
      if (this.totalPages >= newIndex) {
        this.currentPage = newIndex;
      } else {
        this.currentPage = 1;
      }
    }
  }

  close() {
    this.dialogRef.close(this.currentPage);
  }
}
