import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private _snackBar: MatSnackBar) { }

  getMessageError(error:any) {
    const text = error && error.error ? error.error : error;
    switch(text) {
      case "Error: The requested record was not found.":
        return "The requested record was not found.";
      case "Error: Uid is required.":
        return "Uid is required.";
      default:
        console.log(error);
        return "Something went wrong, try again in a few minutes."
    }
  }

  showError(error:string, duration:number = 10) {
    const message = this.getMessageError(error);
    this._snackBar.open(message, null, {
      duration: duration * 1000,
      panelClass: ['main-snackbar']
    });
  }

}
