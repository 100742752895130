<div class="four-columns">
    <div class="label">
        <app-select [label]="'Reading'" [options]="readingOptions" [default]="readingOption" (onValueChange)="onReadingChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Writing'" [options]="writtingOptions" [default]="writtingOption" (onValueChange)="onWrittingChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Listening'" [options]="listeningOptions" [default]="listeningOption" (onValueChange)="onListeningChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Speaking'" [options]="speakingOptions" [default]="speakingOption" (onValueChange)="onSpeakingChange($event)"></app-select>
    </div>
</div>