<form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
    <div *ngFor="let child of c.controls; let i = index">
        <div class="child-content">
            <div [formGroup]="child" class="three-columns">
                <div class="index-child">
                    {{i + 1}}
                </div>
                <div class="label">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" class="form-control"  type="text">
                        <mat-hint *ngIf="child.controls.name.touched  && child.controls.name.errors"  class="errorForm">
                            Name is required
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="label toggle">
                    <mat-slide-toggle (change)="livingInCanada(i)">Is your child living in Canada?</mat-slide-toggle>
                </div>
                <div class="label"></div>
  
                <div class="label" *ngIf="c && c.controls[i] && c.controls[i].controls['livingInCanada'] && c.controls[i].controls['livingInCanada'].value && c.controls[i].controls['livingInCanada'].value === true">
                    <app-select [label]="'Province'" [options]="provinces" (onValueChange)="onProvinceChange(i,$event)"></app-select>
                </div>
                <div class="label" *ngIf="c && c.controls[i] && c.controls[i].controls['province'] && c.controls[i].controls['province'].value && c.controls[i].controls['province'].value === 'British Columbia'">
                    <app-select [label]="'Regional District'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictChange(i,$event)"></app-select>
                </div>
                <div class="label" *ngIf="c && c.controls[i] && c.controls[i].controls['province'] && c.controls[i].controls['province'].value && c.controls[i].controls['province'].value === 'British Columbia'"></div>
                <div class="label toggle" *ngIf="c && c.controls[i] && c.controls[i].controls['livingInCanada'] && c.controls[i].controls['livingInCanada'].value && c.controls[i].controls['livingInCanada'].value === true">
                    <mat-slide-toggle (change)="studyingInCanda(i)">Is your child studying in canada?</mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <button class="action-button" mat-button >Next</button>
</form>