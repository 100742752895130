<div class="header-file" *ngIf="totalPages">
    <div class="actions-buttons">
        <button class="action-button-file" (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
        <button class="action-button-file" (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
        <button class="action-button-file" (click)="rotate()"><mat-icon>refresh</mat-icon></button>
        <button class="action-button-file" (click)="download()"><mat-icon>sim_card_download</mat-icon></button>
    </div>
    <div class="numberOfEelements">
        <button class="action-button-file" (click)="preview()"><mat-icon>navigate_before</mat-icon></button> {{currentPage}} / {{totalPages}}
        <button class="action-button-file" (click)="next()"><mat-icon>navigate_next</mat-icon></button>
    </div>
    <div class="close-full-view">
        <button class="action-button-file" (click)="open()"><mat-icon>zoom_out_map</mat-icon></button>
    </div>
</div>
<div class="container-file">
    <pdf-viewer *ngIf="pdfSrc" [(page)]="currentPage" [src]="pdfSrc" [render-text]="true" [show-all]="true" [autoresize]="true" [show-borders]="true" [original-size]="false" [zoom]="scale" [rotation]="rotationAmount" (after-load-complete)="fileLoaded($event)"
        style="display: block;"></pdf-viewer>
    <div *ngIf="!totalPages" class="loader">
        <app-loader></app-loader>
    </div>
</div>