<div class="four-columns">
    <div class="label">
        <app-select [label]="'Reading'" [options]="options" [default]="defaultOption" (onValueChange)="onReadingChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Writing'" [options]="options" [default]="defaultOption" (onValueChange)="onWrittingChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Listening'" [options]="options" [default]="defaultOption" (onValueChange)="onListeningChange($event)"></app-select>
    </div>
    <div class="label">
        <app-select [label]="'Speaking'" [options]="options" [default]="defaultOption" (onValueChange)="onSpeakingChange($event)"></app-select>
    </div>
</div>