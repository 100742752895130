import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CatalogService } from '../form/catalog.service';
import { IAboutUs, IBenefits, IHowItWorks, IImageSection } from '../form/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public aboutSection: IAboutUs;
  public benefitSection: IBenefits;
  public imageSection: IImageSection;
  public howItWorksSection: IHowItWorks;

  constructor(
    private route: ActivatedRoute, 
    private viewportScroller: ViewportScroller,
    public catalogService: CatalogService, 
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        if(params && params.id) {
          document.querySelector('#' + params.id).scrollIntoView();
          window.scrollBy(0,-75);
        }
      }
    );
    this.catalogService.getPageSections().subscribe((data) => {
      const sections = data || [];
      this.aboutSection = sections.find(s => s.id === 'aboutUs');
      this.benefitSection = sections.find(s => s.id === 'benefits');
      this.imageSection = sections.find(s => s.id === 'imageSection');
      this.howItWorksSection = sections.find(s => s.id === 'howItWorks');
    });
  }

}
