import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CatalogService } from './catalog.service';
import { ICatalogItem, IChannel, ICity, IDataUser, IEducation, IFamily, IForeignExperience, IFourSkills, IHasFriendsInCanada, IHasRelativesInCanada, IHasTemporaryResidence, IIsAnEntrepreneur, IIsLivingInCanada, IJobOffer, ILanguage, IOccupation, IOtherRank, IPartner, IPersonalInformaion, ISchoolLevel, ISimpleCatalog, ITCFCanada, ITEFCanada, IWorkingInCanada, IWorkPermit } from './models';
import { MatStepper } from '@angular/material/stepper';
import { get as _get } from 'lodash';
import { InnerSubscriber } from 'rxjs/internal/InnerSubscriber';
import { MatDialog } from '@angular/material/dialog';
import { DataSavedComponent } from '../shared/data-saved/data-saved.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @ViewChild('stepper') private myStepper: MatStepper;

  // Data User
  public dataUser: IDataUser = {};

  // Boolean Complete Steps
  public isContactInformationComplete: boolean = false;
  public isLanguageDataComplete: boolean = false;
  public isCareerComplete: boolean = false;
  public isResidenceComplete: boolean = false;
  public isEducationComplete: boolean = false;
  public isJobOfferComplete: boolean = false;
  public isWorkExperienceComplete: boolean = false;
  public isEntrepreneurComplete: boolean = false;

  // Boolean Submits
  public wasContactInformationClicked: boolean = false;
  public wasLanguageDataClicked: boolean = false;
  public wasCareerClicked: boolean = false;
  public wasResidenceClicked: boolean = false;
  public wasEducationClicked: boolean = false;
  public wasJobOfferClicked: boolean = false;
  public wasWorkExperienceClicked: boolean = false;
  public wasEntrepreneurClicked: boolean = false;


  // Events
  public submitContactInformation: Subject<void> = new Subject<void>();
  public submitLanguageData: Subject<void> = new Subject<void>();
  public submitCareer: Subject<void> = new Subject<void>();
  public submitResidence: Subject<void> = new Subject<void>();
  public submitEducation: Subject<void> = new Subject<void>();
  public submitJobOffer: Subject<void> = new Subject<void>();
  public submitRelationShip: Subject<void> = new Subject<void>();
  public submitWorkExperience: Subject<void> = new Subject<void>();
  public submitRelativesInCanada: Subject<void> = new Subject<void>();
  public submitFriendsInCanada: Subject<void> = new Subject<void>();

  // Catalog Options
  public channels: IChannel[];
  public relationshipOptions: ICatalogItem[];
  public englishCertifications: ICatalogItem[];
  public frenchCertifications: ICatalogItem[];
  public skillScores: ICatalogItem[];
  public skillScoresFrenchTEF: ITEFCanada;
  public skillScoresFrenchTCF: ITCFCanada;
  public numbersOptions: ICatalogItem[];
  public optionsToStudy: ICatalogItem[];
  public otherLanguageLevels: ICatalogItem[];
  public familyMembers: ICatalogItem[];
  public extendedFamilyMembers: ICatalogItem[];
  public ocupations: ICatalogItem[];
  public periodsOfTime: ICatalogItem[];
  public canadaCities: ICatalogItem[];
  public degreesOfSstudy: ICatalogItem[];
  public workShiftOptions: ICatalogItem[];
  public languages: ILanguage[];
  public cities: ICity[];
  public regionalDistricts: ISimpleCatalog[];
  public provinces: ISimpleCatalog[];
  public schoolLeves: ISchoolLevel[];
  public occupations: IOccupation[];
  public sectors: ICatalogItem[];
  public studiesTypes: ISimpleCatalog[];
  public schools: ICatalogItem[];
  public workPermits: ICatalogItem[];

  // Values 
  public channel: string;
  public nativeLanguageOption: ILanguage;
  public firstStudyOption: ICatalogItem;
  public secondStudyOption: ICatalogItem;
  public hasSecondOption: boolean = false;
  public hasEnglishCertificate: boolean = false;
  public hasFrenchCertificate: boolean = false;
  public hasFamilyInCanada: boolean = false;
  public hasExtendedFamilyInCanada: boolean = false;
  public hasWorkExperience: boolean = false;
  public hasWorkExperienceInCanada: boolean = false;
  public hasCanadaStudies: boolean = false;
  public hasAnOffer: boolean = false;
  public isWorking: boolean = false;
  public hasWorkPermit: boolean = false;
  public isLoading: boolean = false;

  // Personal Information
  public name: string;
  public lastname: string;
  public email: string;
  public phone: string;
  public birthdate: Date;
  public amountFunds: number;
  public relativesNumber: number;
  public hasCriminalRecord: boolean = false;
  public hasUnresolvedDisputes: boolean = false;
  public hasDriverLicense: boolean = false;
  public livedColdWeather: boolean = false;
  public invalidEmail: boolean = false;

  public hasTemporaryResidence: boolean = false;
  public hasValidResidence: boolean = false;
  public isLivingInCanada: boolean = false;
  public livingInRuralArea: boolean = false;
  public livingInColdClimate: boolean = false;
  public hasOwnProperty: boolean = false;
  public previouslyTraveled: boolean = false;
  public hasCanadaPostSecundaryStudies: boolean = false;
  public hasPostSecundaryStudies: boolean = false;
  public hasFormalPartner: boolean = false;

  public residencyStatusMonthsLeft: number;
  public monthsLivingInCanda: number;

  public countryCode: any;
  public country: any;
  public relationShip: ICatalogItem;
  public numberChildren: number;
  public englishCertification: ICatalogItem;
  public frenchCertification: ICatalogItem;
  public customLevelEnglish: ICatalogItem;
  public customLevelFrench: ICatalogItem;
  public familyInCanada: ICatalogItem[];
  public extendedFamilyInCanada: ICatalogItem[];
  public workExperience: ICatalogItem;
  public workExperienceInCanada: ICatalogItem;
  public periodExperience: ICatalogItem;
  public workShift: ICatalogItem;
  public periodExperienceInCanda: ICatalogItem;
  public workShiftInCanada: ICatalogItem;  
  public workedPlace: ICatalogItem;
  public lastDegreeOfStudies: ISchoolLevel;
  public lastDegreeOfStudiesInCanada: ICatalogItem;
  public offerSector: ICatalogItem;
  public offerPlace: ICatalogItem;

  public userEnglishFourSkills: IFourSkills;
  public userFrenchFourSkills: IFourSkills;
  public userDuolingoPoints: number = 0;

  public userLivingProvince: ISimpleCatalog;
  public userLivingRegionalDistrict: ISimpleCatalog;
  public userLivingCity: ICity;
  public hasCanadianDriverLicense: boolean;
  public isUserVolunteer: boolean;
  public userRuralAreaCity: ICity;
  public monthsLivingInRuralArea: number;
  public userColdClimateCity: ICity;
  public monthsLivingInColdClimate: number;
  public userPropertyProvince: ISimpleCatalog;
  public userPropertyCity: ISimpleCatalog;
  public monthsWithOwnProperty: number;
  public userTravelProvince: ISimpleCatalog;
  public userTravelRegionalDistrict: ISimpleCatalog;
  public userTravelCity: ICity;
  public previouslyTraveledMonths: number;

  public educationOccupation:IOccupation;
  public educationSector: ICatalogItem;
  public educationProvince: ISimpleCatalog;
  public educationCity: ICity;
  public monthsEducationTime: number;
  public educationType: ISimpleCatalog;
  public educationSchool: ICatalogItem;
  public postSecundarySchoolLevel: ISchoolLevel;
  public provincePostSecundary: ISimpleCatalog;
  public cityPostSecundary: ICity;
  public ITABC: boolean;
  public provinceJobOffer: ISimpleCatalog;
  public regionJobOffer: ISimpleCatalog;
  public cityJobOffer: ICity;
  public offerHours: number;
  public jobOfferOccupation: IOccupation;
  public offerType: ISimpleCatalog;
  public offerMonthWage: number;
  public isIndefiniteOffer: boolean;
  public hasAMentor: boolean;

  public monthsOfExperienceOutside: number;
  public isWorkingInCanada: boolean;
  public workPermitRemainingMonths: number;
  public hasACanadianWorkPermit: boolean;
  public workPermit: ICatalogItem;
  public provinceWork: ISimpleCatalog;
  public regionalDistrictWork: ISimpleCatalog;
  public cityWork: ICity;
  public sectorWork: ICatalogItem;
  public workOccupation: IOccupation;
  public workType: ISimpleCatalog;
  public workHoursPerWeek: number;
  public wagePerHour: number;
  public wagePerMonth: number;
  public monthsOfExperience: number;
  public monthsOfExperience18: number;
  public additionalWorkExperience: boolean;
  public provinceExtraWorkExperience: ISimpleCatalog;
  public regionalDistrictExtraWork: ISimpleCatalog;
  public cityExtraWork: ICity;
  public occupationExtraWorkExperience: IOccupation;
  public extraMonthsExperience: number;
  public typeExtraWork: ISimpleCatalog;
  public monthsWageExtraExperience: number;

  public directRelativesInCanada: number;
  public friendsInCanada: number;

  public isAnEntrepreneur: boolean;
  public entrepreneurSector: ICatalogItem;
  public numberJobs: number;
  public amountInvested: number;
  public entrepreneurMonths: number;
  public isABusinessRural: boolean;
  public isBusinessExportOriented: boolean;
  public isAnExistingBusiness: boolean;

  public otherEnglishCertificateName: string;
  public otherFrenchCertificateName: string;

  public familiSize: number;
  public defaultChannelID: string;
  public defaultChannel: IChannel;

  public optionToStudyFiltered = new FormControl();

  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(
    private formBuilder: FormBuilder, 
    public catalogService: CatalogService, 
    private ref: ChangeDetectorRef, 
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.defaultChannelID = this.route.snapshot.paramMap.get('id');
    this.loadCatalogsOptions();  
  }

  loadCatalogsOptions() {
    this.relationshipOptions = this.catalogService.getRelationshipStatus();
    this.englishCertifications = this.catalogService.getEnglishCertificationTypes();
    this.frenchCertifications = this.catalogService.getFrenchCertificationTypes();
    this.skillScores = this.catalogService.getSkillScore();
    this.skillScoresFrenchTEF = this.catalogService.getSkillScoreTEF();
    this.skillScoresFrenchTCF = this.catalogService.getSkillScoreTCF();
    this.numbersOptions = this.catalogService.getNumbersOptions();
    this.otherLanguageLevels = this.catalogService.getOtherLanguageLevels();
    this.familyMembers = this.catalogService.getFamilyMembers();
    this.extendedFamilyMembers = this.catalogService.getExtendedFamilyMembers();
    this.ocupations = this.catalogService.getOccupationOptions();
    this.periodsOfTime = this.catalogService.getPeriodOfTimeOptions();
    this.canadaCities = this.catalogService.getCanadaCities();
    this.degreesOfSstudy = this.catalogService.getDegreesOfStudy();
    this.workShiftOptions = this.catalogService.getWorkShiftOptions();
    this.studiesTypes = [
      {'label':'Full time', value:'full-time'},
      {'label':'Part time', value:'part-time'},
    ]
    this.catalogService.getOptionsToStudy()
      .subscribe(options => {
        this.optionsToStudy = options ? options : [];
    });
    this.catalogService.getLanguages().subscribe(languages => {
      this.languages =  languages ? languages : [];
    });
    this.catalogService.getCities().subscribe(cities => {
      this.cities =  cities ? cities : [];
    });
    this.catalogService.getProvinces().subscribe(provinces => {
      this.provinces =  provinces ? provinces : [];
    });
    this.catalogService.getRegionalDistricts().subscribe(districts => {
      this.regionalDistricts =  districts ? districts : [];
    });
    this.catalogService.getSchoolLevels().subscribe(levels => {
      this.schoolLeves =  levels ? levels : [];
    });
    this.catalogService.getOccupations().subscribe(occupations => {
      this.occupations =  occupations ? occupations : [];
    });
    this.catalogService.getSectors().subscribe(sectors => {
      this.sectors =  sectors ? sectors : [];
    });
    this.catalogService.getSchools().subscribe(schools => {
      const defaultSchool: ICatalogItem = {uid: '', label: 'Other'};
      this.schools =  schools ? schools : [];
      this.schools.push(defaultSchool);
    });
    this.catalogService.getWorkPermits().subscribe(workPermits => {
      this.workPermits =  workPermits ? workPermits : [];
    });
    this.catalogService.getChannels().subscribe(channels => {
      this.channels = channels ? channels : [];
      if(this.defaultChannelID) {
        const channel = channels.find(channel => channel.uid === this.defaultChannelID);
        if(channel) {
          this.defaultChannel = channel;
          this.channel = _get(this.defaultChannel,'label');
        }
      }
    });
  }

  onSubmitContactInfo(){
    this.wasContactInformationClicked = true;
    this.submitContactInformation.next();
    this.validSubmitContact();
    if(this.isContactInformationComplete) {
      const month = this.birthdate.getUTCMonth() + 1; //months from 1-12
      const day = this.birthdate.getUTCDate();
      const year = this.birthdate.getUTCFullYear();
      const personalInformation: IPersonalInformaion = {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
        countryCode: this.countryCode,
        phone: this.phone,
        country: this.country,
        channel: this.channel,
        birthdate: year + "/" + month + "/" + day,
        hasCriminalRecord: this.hasCriminalRecord,
        hasUnresolvedDisputes: this.hasUnresolvedDisputes,
        nativeLanguage: this.nativeLanguageOption.label,
        livedColdWeather: this.livedColdWeather,
        liquidFunds: this.amountFunds,
        relativesToMigrate: this.relativesNumber,
        hasDriversLicence: this.hasDriverLicense
      }
      console.log(personalInformation);
      this.dataUser.personalInformation = personalInformation;
      window.scroll(0,0);
    }
  }

  onSubmitLanguage(){
    this.wasLanguageDataClicked = true;
    this.submitLanguageData.next();
    this.validSubmitLangugauge();
    if(this.isLanguageDataComplete) {
      this.myStepper.next();
    }
    if(this.isLanguageDataComplete) {
      window.scroll(0,0);
    }
  }

  onSubmitCareer(){
    this.wasCareerClicked = true;
    this.submitCareer.next();
    this.validSubmitCareer();
    if(this.isCareerComplete) {
      window.scroll(0,0);
    }
  }

  onSubmitResidence(){
    this.wasResidenceClicked = true;
    this.submitResidence.next();
    this.validSubmitResidence();
    if(this.isResidenceComplete) {
      const hasTemporaryResidence: IHasTemporaryResidence = {
        status: this.hasTemporaryResidence ? this.hasTemporaryResidence : false,
        monthsLeft: this.residencyStatusMonthsLeft ? this.residencyStatusMonthsLeft : 0,
      }
      this.dataUser.hasTemporaryResidence = hasTemporaryResidence;
     
      const isLivingInCanada: IIsLivingInCanada = {
        status: this.isLivingInCanada ? this.isLivingInCanada : false,
        hasValidResidence: this.hasValidResidence ? this.hasValidResidence : false,
        months: this.monthsLivingInCanda ? this.monthsLivingInCanda : 0,
        volunteer: this.isUserVolunteer ? this.isUserVolunteer : false,
        province: _get(this.userLivingProvince,'value') ?  _get(this.userLivingProvince,'value') : '',
        regionalDistrict: _get(this.userLivingRegionalDistrict,'value') ?  _get(this.userLivingRegionalDistrict,'value') : null,
        city: _get(this.userLivingCity,'name') ?  _get(this.userLivingCity,'name') : null,
        hasDriversLicence: this.hasCanadianDriverLicense ? this.hasCanadianDriverLicense : false,
        livingInRuralArea: {
          status: this.livingInRuralArea ? this.livingInRuralArea : false,
          city:  _get(this.userRuralAreaCity ,'name') ? _get(this.userRuralAreaCity ,'name') : null,
          months: this.monthsLivingInRuralArea ? this.monthsLivingInRuralArea : 0
        },
        livingInColdClimate: {
          status: this.livingInColdClimate ? this.livingInColdClimate : false,
          city:  _get(this.userColdClimateCity ,'name') ? _get(this.userColdClimateCity ,'name') : null,
          months: this.monthsLivingInColdClimate ? this.monthsLivingInColdClimate : 0,
        },
        hasOwnProperty: {
          status: this.hasOwnProperty ? this.hasOwnProperty : false,
          province: _get(this.userPropertyProvince ,'value') ? _get(this.userPropertyProvince ,'value') : null,
          city: _get(this.userPropertyCity ,'name') ? _get(this.userPropertyCity ,'name') : null,
          months: this.monthsWithOwnProperty ? this.monthsWithOwnProperty : 0
        },
        previousTravel: {
          regionalDistrict: _get(this.userTravelRegionalDistrict,'value') ? _get(this.userTravelRegionalDistrict,'value') : null,
          city: _get(this.userTravelCity,'name') ? _get(this.userTravelCity,'name') : null,
          months: this.previouslyTraveledMonths ? this.previouslyTraveledMonths : 0
        }
      }
      this.dataUser.isLivingInCanada = isLivingInCanada;

      
      console.log(this.dataUser);
      window.scroll(0,0);
    }
  }

  onSubmitEducation() {
    this.wasEducationClicked = true;
    this.submitEducation.next();
    this.validSubmitEducation();
    
    if(this.isEducationComplete) {
      const educationData: IEducation = {
        schoolLevel: {
          label: _get(this.lastDegreeOfStudies,'label') || '',
          order: _get(this.lastDegreeOfStudies,'order') || 0,
          uid: _get(this.lastDegreeOfStudies,'uid') || '',
        },
        occupation: {
          uid: _get(this.educationOccupation,'uid') || '',
          description: _get(this.educationOccupation,'label') || '',
          type: _get(this.educationOccupation,'type') || '',
        },
        sector: _get(this.educationSector,'label') || '',
        province: _get(this.educationProvince,'value') || '',
        city: _get(this.educationCity,'name') || '',
        timeInMonths: this.monthsEducationTime || 0,
        type: _get(this.educationType,'value') || '',
        school: {
          uid: _get(this.educationSchool,'uid') || '',
          label: _get(this.educationSchool,'label') || '',
        },
        postSecondaryEducation: {
          status: this.hasPostSecundaryStudies ? this.hasPostSecundaryStudies : false,
          InCanada: this.hasCanadaPostSecundaryStudies ? this.hasCanadaPostSecundaryStudies : false,
          levelValue: _get(this.postSecundarySchoolLevel,'label') || '',
          levelID: _get(this.postSecundarySchoolLevel,'uid') || '',
          city: _get(this.cityPostSecundary, 'label') || '',
          province: _get(this.provincePostSecundary,'label') || '',
          ITABC: this.ITABC ? this.ITABC : false,
        }
      }
      this.dataUser.education = educationData;
      console.log(educationData);
    }
  }

  onSubmitJobOffer() {
    this.wasJobOfferClicked = true;
    this.submitJobOffer.next();
    this.validSubmitJobOffer();
    if(this.isJobOfferComplete) {
      const hasJobOffer: IJobOffer = {
        status: this.hasAnOffer ? this.hasAnOffer : false,
        province: _get(this.provinceJobOffer,'value') || '',
        regionalDistrict: _get(this.regionJobOffer,'value') || '',
        city: _get(this.cityJobOffer,'name') || '',
        hoursPerWeek: this.offerHours || 0,
        occupation: {
          uid: _get(this.jobOfferOccupation,'uid') || '',
          description: _get(this.jobOfferOccupation,'label') || '',
          type: _get(this.jobOfferOccupation,'type') || '',
        },
        type: _get(this.offerType, 'value') || '',
        monthWage: this.offerMonthWage || 0,
        hasAMentor: this.hasAMentor ? this.hasAMentor : false,
        undefinedTime: this.isIndefiniteOffer ? this.isIndefiniteOffer : false,
      }
      this.dataUser.hasJobOffer = hasJobOffer;
      console.log(hasJobOffer);
    }
  }

  onSubmitWorkExperience() {
    this.wasWorkExperienceClicked = true;
    this.submitWorkExperience.next();
    this.validSubmitWorkExperience();
    if(this.isWorkExperienceComplete) {
      const foreignExperience: IForeignExperience = {
        monthsOfExperience: this.monthsOfExperienceOutside ? this.monthsOfExperienceOutside : 0
      }
      this.dataUser.foreignExperience = foreignExperience;
      
      const workPermit: IWorkPermit = {
        uid: _get(this.workPermit,'uid') || '',
        label: _get(this.workPermit,'label') || '',
        remainingMonths: this.workPermitRemainingMonths || 0,
      }
      this.dataUser.workPermit = workPermit;

      const workingInCanada: IWorkingInCanada = {
        status: this.isWorkingInCanada ? this.isWorkingInCanada : false,
        province: _get(this.provinceWork,'value') || '',
        regionalDistrict: _get(this.regionalDistrictWork,'value') || '',
        sector: _get(this.sectorWork,'label') || '',
        occupation: {
          uid: _get(this.workOccupation,'uid') || '',
          description: _get(this.workOccupation,'label') || '',
          type: _get(this.workOccupation,'type') || '',
        },
        type: _get(this.workType,'value') || '',
        hoursPerWeek: this.workHoursPerWeek || 0,
        wagePerHour: this.wagePerHour || 0,
        monthWage: this.wagePerMonth || 0,
        monthsOfExperience: this.monthsOfExperience || 0,
        monthsInLast18: this.monthsOfExperience18 || 0,
        additionalExperience: {
          status: this.additionalWorkExperience ? this.additionalWorkExperience : false,
          city: _get(this.cityExtraWork,'name') || '',
          regionalDistrict: _get(this.regionalDistrictExtraWork,'value') || '',
          province: _get(this.provinceExtraWorkExperience,'value') || '',
          occupation: {
              uid: _get(this.occupationExtraWorkExperience,'uid') || '',
              description: _get(this.occupationExtraWorkExperience,'label') || '',
              type: _get(this.occupationExtraWorkExperience,'type') || '',
          },
          monthsOfExperience: this.extraMonthsExperience || 0,
          type: _get(this.typeExtraWork,'value') || '',
          monthWage: this.monthsWageExtraExperience || 0,
        }
      }
      this.dataUser.isWorkingInCanada = workingInCanada;


      console.log(this.dataUser);
    }
  }

  onSubmitRelatives(data:any) {
    const relatives = _get(data,'children') || [];
    const relativesData: IHasRelativesInCanada = {
      status: this.directRelativesInCanada > 0 ? true : false,
      relatives: relatives ? relatives : [],
    };
    this.dataUser.hasRelativesInCanada = relativesData;
    this.myStepper.next();
    window.scroll(0,0);
  }

  onSubmitFriends(data:any) {
    const friends = _get(data,'children') || [];
    const friendsData: IHasFriendsInCanada = {
      status: this.friendsInCanada > 0 ? true : false,
      friends: friends ? friends : [],
    };
    this.dataUser.hasFriendsInCanada = friendsData;
    this.myStepper.next();
    window.scroll(0,0);    
  }

  onSubmitEntrepreneur() {
    this.wasEntrepreneurClicked = true;
    if(this.isAnEntrepreneur) {
      const entrepreneurData: IIsAnEntrepreneur = {
        status: this.isAnEntrepreneur ? this.isAnEntrepreneur : false,
        monthsOfExperience: this.entrepreneurMonths ? this.entrepreneurMonths : 0,
        jobsCreated: this.numberJobs ? this.numberJobs : 0,
        amountInvested: this.amountInvested ? this.amountInvested : 0,
        isRuralType: this.isABusinessRural ? this.isABusinessRural : false,
        isExportOrientedBusiness: this.isBusinessExportOriented ? this.isBusinessExportOriented : false,
        isAnExistingBusiness: this.isAnExistingBusiness ? this.isAnExistingBusiness : false,
        economicDeveloperSector: {
          status: true,
          value: _get(this.entrepreneurSector,'label') || ''
        }
      }
      this.dataUser.isAnEntrepreneur = entrepreneurData;
      console.log(this.dataUser);
    }
  }

  onSubmitFamily(data:IFamily) {
    this.dataUser.family = data;
    this.myStepper.next();
  }

  validSubmitContact() {
    if(this.name && this.lastname && this.email && this.phone 
      && this.countryCode && this.country && this.birthdate 
      && this.amountFunds && this.relativesNumber && this.channel) {
        if(this.validEmail(this.email)) {
          this.isContactInformationComplete = true;
          this.invalidEmail = false;
        } else {
          this.isContactInformationComplete = false;
          this.invalidEmail = true;
        }
    } else {
      this.isContactInformationComplete = false;
    }
  }

  validEmail(email: string) {
    return email.toLocaleLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  validSubmitLangugauge() {
    if(!this.hasEnglishCertificate && !this.hasFrenchCertificate) {
      this.isLanguageDataComplete = true;
    } else {
      this.isLanguageDataComplete = false;
    }

    let validEnglishPart = true;
    if(this.hasEnglishCertificate) {
      validEnglishPart = false;
      const certificationType = _get(this.englishCertification,'uid') || '';
      switch(certificationType) {
        case 'IELTS': 
          if(this.userEnglishFourSkills) {
            this.dataUser.hasEnglishLevel = {
              status: true,
              ielts: this.userEnglishFourSkills,
            }
            validEnglishPart = true;
          }
          break;
        case 'TOEFL':
          if(this.userEnglishFourSkills) {
            this.dataUser.hasEnglishLevel = {
              status: true,
              toefl: this.userEnglishFourSkills,
            }
            validEnglishPart = true;
          }
          break;
        case 'Duolingo': 
          if(this.userDuolingoPoints >= 0) {
            const duolingoPoints = {points: this.userDuolingoPoints}
            this.dataUser.hasEnglishLevel = {
              status: true,
              duolingo: duolingoPoints,
            }
            validEnglishPart = true;
          }
          break;
        case 'Other':
          if(this.customLevelEnglish) {
            const otherLevel = _get(this.customLevelEnglish,'uid');
            const englishCertificate:IOtherRank = 
            {level: otherLevel, name:this.otherEnglishCertificateName || ''}
            this.dataUser.hasEnglishLevel = {
              status: true,
              other: englishCertificate,
            }
            validEnglishPart = true;
          }
          break;
        default:
          break;
      }
    }

    let validFrenchPart = true;
    if(this.hasFrenchCertificate) {
      validFrenchPart = false;
      const certificationType = _get(this.frenchCertification,'uid') || '';
      switch(certificationType) {
        case 'TEF': 
          if(this.userFrenchFourSkills) {
            this.dataUser.hasFrenchLevel = {
              status: true,
              tef: this.userFrenchFourSkills,
            }
            validFrenchPart = true;
          }
          break;
        case 'TCF':
          if(this.userFrenchFourSkills) {
            this.dataUser.hasFrenchLevel = {
              status: true,
              tcf: this.userFrenchFourSkills,
            }
            validFrenchPart = true;
          }
          break;
        case 'Other':
          if(this.customLevelFrench) {
            const otherLevel = _get(this.customLevelFrench,'uid');
            const frenchCertificate:IOtherRank = 
            {level: otherLevel, name:this.otherFrenchCertificateName || ''}
            this.dataUser.hasFrenchLevel = {
              status: true,
              other: frenchCertificate,
            }
            validFrenchPart = true;
          }
          break;
        default:
          break;
      }
    console.log(this.dataUser);
    
    }

    if(!this.isLanguageDataComplete && (!validEnglishPart || !validFrenchPart)) {
      this.isLanguageDataComplete = false;
    } else {
      this.isLanguageDataComplete = true;
    }

    this.ref.detectChanges();


  }

  validSubmitCareer() {
    if(this.firstStudyOption) {
      if(this.hasSecondOption) {
        this.isCareerComplete = 
        this.secondStudyOption ? true : false;
      } else {
        this.isCareerComplete = true;
      }
    } else {
      this.isCareerComplete = false;
    } 
  }

  validSubmitResidence() {
    this.isResidenceComplete = true;
  }

  validSubmitEducation() {
    this.isEducationComplete = true;
  }

  validSubmitJobOffer() {
    this.isJobOfferComplete = true;
  }

  validSubmitWorkExperience() {
    this.isWorkExperienceComplete = true;
  }

  validSubmitEntrepreneur() {
    this.isEntrepreneurComplete = true;
  }

  onSubmitRelationship(){
    this.submitRelationShip.next();
  }

  switchHasCriminalRecord() {
    this.hasCriminalRecord = !this.hasCriminalRecord;
    this.validSubmitCareer();
  }

  switchHasUnresolvedDisputes() {
    this.hasUnresolvedDisputes = !this.hasUnresolvedDisputes;
    this.validSubmitCareer();
  }

  switchHasDriverLicense() {
    this.hasDriverLicense = !this.hasDriverLicense;
    this.validSubmitCareer();
  }

  switchLivedColdWeather() {
    this.livedColdWeather = !this.livedColdWeather;
    this.validSubmitCareer();
  }

  switchSecondOption() {
    this.hasSecondOption = !this.hasSecondOption;
    if(!this.hasSecondOption) {
      this.secondStudyOption = null;
    }
    this.validSubmitCareer();
  }

  switchTemporaryResidence() {
    this.hasTemporaryResidence = !this.hasTemporaryResidence;
    this.validSubmitResidence();
  }

  switchValidResidence() {
    this.hasValidResidence = !this.hasValidResidence;
    this.validSubmitResidence();
  }

  switchLivingInCanada() {
    this.isLivingInCanada = !this.isLivingInCanada;
    this.validSubmitResidence();
  }

  switchLivingInRuralArea() {
    this.livingInRuralArea = !this.livingInRuralArea;
    this.validSubmitResidence();
  }

  switchLivingInColdClimate() {
    this.livingInColdClimate = !this.livingInColdClimate;
    this.validSubmitResidence();
  }

  switchHasOwnProperty() {
    this.hasOwnProperty = !this.hasOwnProperty;
    this.validSubmitResidence();
  }

  switchPreviouslyTraveled() {
    this.previouslyTraveled = !this.previouslyTraveled;
    this.validSubmitResidence();
  }

  switchHasCanidianDriverLicense() {
    this.hasCanadianDriverLicense = !this.hasCanadianDriverLicense;
    this.validSubmitResidence();
  }

  switchUserVolunteer() {
    this.isUserVolunteer = !this.isUserVolunteer;
    this.validSubmitResidence();
  }

  switchEnglishCertification() {
    this.hasEnglishCertificate = !this.hasEnglishCertificate;
    if(!this.hasEnglishCertificate) {
      this.englishCertification = null;
      this.englishCertification
    }
  }

  switchFrenchCertification() {
    this.hasFrenchCertificate = !this.hasFrenchCertificate;
    if(!this.hasFrenchCertificate) {
      this.frenchCertification = null;
    }
  }

  switchFamilyInCanada() {
    this.hasFamilyInCanada = !this.hasFamilyInCanada;
    if(!this.hasFamilyInCanada) {
      this.familyInCanada = null;
    }
  }

  switchExtendedFamilyInCanada() {
    this.hasExtendedFamilyInCanada = !this.hasExtendedFamilyInCanada;
    if(!this.hasExtendedFamilyInCanada) {
      this.extendedFamilyInCanada = null;
    }
  }

  switchWorkExperience() {
    this.hasWorkExperience = !this.hasWorkExperience;
    if(!this.hasWorkExperience) {
      this.workExperience = null;
      this.periodExperience = null;
      this.workShift = null;
    }
  }

  switchWorkExperienceInCanada() {
    this.hasWorkExperienceInCanada = !this.hasWorkExperienceInCanada;
    if(!this.hasWorkExperienceInCanada) {
      this.workExperienceInCanada = null;
      this.periodExperienceInCanda = null;
      this.workShiftInCanada = null;
    }
  }

  switchHasCanadaStudies() {
    this.hasCanadaStudies = !this.hasCanadaStudies;
  }

  switchHasCanadaPostSecundaryStudies() {
    this.hasCanadaPostSecundaryStudies = !this.hasCanadaPostSecundaryStudies;
  }

  switchHasPostSecundaryStudies() {
    this.hasPostSecundaryStudies = !this.hasPostSecundaryStudies;
  }

  switchHasAnOffer() {
    this.hasAnOffer = !this.hasAnOffer;
    if(!this.hasAnOffer) {
      this.offerSector = null;
      this.offerPlace = null;
    }
  }

  switchIsWorking() {
    this.isWorking = !this.isWorking;
  }

  switchHasWorkPermit() {
    this.hasWorkPermit = !this.hasWorkPermit;
  }

  switchITABC() {
    this.ITABC = !this.ITABC;
  }

  switchJobOffer() {
    this.hasAnOffer = !this.hasAnOffer;
  }

  switchIndefiniteOffer() {
    this.isIndefiniteOffer = !this.isIndefiniteOffer
  }

  switchHasAMentor() {
    this.hasAMentor = !this.hasAMentor;
  }

  switchWorkingInCanada() {
    this.isWorkingInCanada = !this.isWorkingInCanada;
  }

  switchHasACanadianWorkPermit() {
    this.hasACanadianWorkPermit = !this.hasACanadianWorkPermit;
  }

  switchAdditionalWorkExperience() {
    this.additionalWorkExperience = !this.additionalWorkExperience;
  }

  switchEntrepreneur() {
    this.isAnEntrepreneur = ! this.isAnEntrepreneur;
  }

  switchIsRuralBusiness() {
    this.isABusinessRural = !this.isABusinessRural;
  }

  switchIsExportOriented() {
    this.isBusinessExportOriented = !this.isBusinessExportOriented;
  }

  switchIsAExistingBusiness() {
    this.isAnExistingBusiness = !this.isAnExistingBusiness;
  }

  switchFormalPartner() {
    this.hasFormalPartner = !this.hasFormalPartner;
  }

  onChannelChange(option: any) {
    this.channel = option && option.value && option.value.label ? option.value.label : '';
    this.validSubmitContact();
  }

  onNativeLanguageChange(option: any) {
    this.nativeLanguageOption = option;
    this.validSubmitContact();
  }

  onFirstStudyOptionChange(option:any) {
    this.firstStudyOption = option;
    this.validSubmitCareer();
  }

  onSecondStudyOptionChange(option:any) {
    this.secondStudyOption = option;
    this.validSubmitCareer();
  }

  onCountryCodeChange(option:any) {
    this.countryCode = option;
    this.validSubmitContact();
  }

  onCountryChange(option:any) {
    this.country = option;
    this.validSubmitContact();
  }

  onBirthdateChange(option:any) {
    this.birthdate = option;
    this.validSubmitContact();
  }

  onRelationStatusChange(option:any) {
    this.relationShip = option;
  }

  onNumberChildrenChange(option:any) {
    const value = option && option.uid ? option.uid : 0;
    this.numberChildren = value ;
  }

  onEnglishCertificationChange(option:any) {
    this.englishCertification = option;
    if(option.uid === 'Other' && this.otherLanguageLevels[0]) {
      this.customLevelEnglish = this.otherLanguageLevels[0];
    }
  }

  onFrenchCertificationChange(option:any) {
    this.frenchCertification = option;
    if(option.uid === 'Other' && this.otherLanguageLevels[0]) {
      this.customLevelFrench = this.otherLanguageLevels[0];
    }
  }

  onCustomLevelEnglishChange(option:any) {
    this.customLevelEnglish = option;
  }

  onCustomLevelFrenchChange(option:any) {
    this.customLevelFrench = option;
  }

  onFamilyMembersChange(options:any) {
    this.familyInCanada = options;
  }

  onExtendedFamilyMembersChange(options:any) {
    this.extendedFamilyInCanada = options;
  }

  onWorkExperienceChange(option:any) {
    this.workExperience = option;
  }

  onWorkExperienceInCanadaChange(option:any) {
    this.workExperienceInCanada = option;
  }

  onPeriodExperienceChange(option:any) {
    this.periodExperience = option;
  }

  onWorkShiftChange(option:any) {
    this.workShift = option;
  }

  onPeriodExperienceInCanadaChange(option:any) {
    this.periodExperienceInCanda = option;
  }

  onWorkShiftInCanadaChange(option:any) {
    this.workShiftInCanada = option;
  }

  onWorkedPlaceChange(option:any) {
    this.workedPlace = option;
  }

  onLastDegreeChange(option:any) {
    this.lastDegreeOfStudies = option;
  }

  onLastDegreeInCanadaChange(option:any) {
    this.lastDegreeOfStudiesInCanada = option;
  }

  onOfferSectorChange(option:any) {
    this.offerSector = option;
  }

  onOfferPlaceChange(option:any) {
    this.offerPlace = option;
  }

  onChangeUserEnglishForSkills(data: IFourSkills) {
    this.userEnglishFourSkills = data;
  }

  onChangeUserFrenchForSkills(data: IFourSkills) {
    this.userFrenchFourSkills = data;
  }

  onProvinceChange(data: ISimpleCatalog) {
    this.userLivingProvince = data;
    this.userLivingRegionalDistrict = null;
    this.userLivingCity = null;    
  }

  onRegionalDistrictChange(data: ISimpleCatalog) {
    this.userLivingRegionalDistrict = data;
  }

  onCityChange(data: ICity) {
    this.userLivingCity = data;
  }

  onRuralAreaCityChange(data: ICity) {
    this.userRuralAreaCity = data;
  }

  onColdClimateCityChange(data: ICity) {
    this.userColdClimateCity = data;
  }

  onUserPropertyProvinceChange(data: ISimpleCatalog) {
    this.userPropertyProvince = data;
  }

  onUserPropertyCityChange(data: ISimpleCatalog) {
    this.userPropertyCity = data;
  }

  onTravelProvinceChange(data: ISimpleCatalog) {
    this.userTravelProvince = data;
  }

  onRegionalDistrictTravelChange(data: ISimpleCatalog) {
    this.userTravelRegionalDistrict = data;
  }

  onCityTravelChange(data: ICity) {
    this.userTravelCity = data;
  }

  onEducationOccupationChange(data: IOccupation) {
    this.educationOccupation = data;
  }

  onEducationSectorChange(data: ICatalogItem) {
    this.educationSector = data;
  }

  onEducationProvinceChange(data: ISimpleCatalog) {
    this.educationProvince = data;
  }

  onEducationCityChange(data: ICity) {
    this.educationCity = data;
  }

  onEducationTypeChange(data: ISimpleCatalog) {
    this.educationType = data;
  }

  onEducationSchoolChange(data: ICatalogItem) {
    this.educationSchool = data;
  }

  onPostSecundaryDegreeChange(data: ISchoolLevel) {
    this.postSecundarySchoolLevel = data;
  }

  onProvincePostSecundaryChange(data: ISimpleCatalog) {
    this.provincePostSecundary = data;
  }
  
  onCityPostSecundaryChange(data: ICity) {
    this.cityPostSecundary = data;
  }

  onProvinceJobOfferChange(data: ISimpleCatalog) {
    this.provinceJobOffer = data;
  }

  onRegionJobOfferChange(data: ISimpleCatalog) {
    this.regionJobOffer = data;
  }

  onCityJobOfferChange(data: ICity) {
    this.cityJobOffer = data;
  }

  onJobOfferOccupationChange(data: IOccupation) {
    this.jobOfferOccupation = data;
  }

  onOfferTypeChange(data: ISimpleCatalog) {
    this.offerType = data;
  }

  onWorkPermitChange(data: ICatalogItem) {
    this.workPermit = data;
  }

  onProvinceWorkChange(data: ISimpleCatalog) {
    this.provinceWork = data;
  }

  onRegionalDistrictWorkChange(data: ISimpleCatalog) {
    this.regionalDistrictWork = data;
  }

  onCityWorkChange(data: ICity) {
    this.cityWork = data;
  }

  onSectorWork(data: ICatalogItem) {
    this.sectorWork = data;
  }

  onWorkOccupationChange(data: IOccupation) {
    this.workOccupation = data;
  }

  onWorkTypeChange(data: ISimpleCatalog) {
    this.workType = data;
  }

  onProvinceExtraWork(data:ISimpleCatalog) {
    this.provinceExtraWorkExperience = data;
  }

  onRegionalDistrictExtraWorkChange(data:ISimpleCatalog) {
    this.regionalDistrictExtraWork = data;
  }

  onCityExtraWorkChange(data: ICity) {
    this.cityExtraWork = data;
  }

  onExtraWorkExperienceChange(data: IOccupation) {
    this.occupationExtraWorkExperience = data;
  }

  onExtraWorkTypeChange(data: ISimpleCatalog) {
    this.typeExtraWork = data;
  }

  onDirectRelativesChange(option:any) {
    const value = option && option.uid ? option.uid : 0;
    this.directRelativesInCanada = value ;
  }

  onFriendsChange(option:any) {
    const value = option && option.uid ? option.uid : 0;
    this.friendsInCanada = value ;
  }

  onEntrepreneurSectorChange(data: ICatalogItem) {
    this.entrepreneurSector = data;
  }

  onSubmitPartner(data: IPartner) {
    const currentDataFamily = _get(this.dataUser,'family');
    const newData = {...currentDataFamily, ...{partner: data}}
    this.dataUser.family = newData;
    this.sendData();
  }

  sendData() {
    this.isLoading = true;
    this.dataUser = this.validMainStructure(this.dataUser);
    this.catalogService.saveApplication(this.dataUser).subscribe(data => {
      this.isLoading = false;
      const uid: string = _get(data,'uid');
      const email: string = _get(data,'personalInformation.email');
      localStorage.setItem('data', uid);
      localStorage.setItem('email', email);
      this.openDialog(uid, email)
    });
  }

  validMainStructure(data: IDataUser) {
    const validData: IDataUser = {
      personalInformation: _get(data,'personalInformation') || {},
      hasEnglishLevel: _get(data,'hasEnglishLevel') || {},
      hasFrenchLevel: _get(data,'hasFrenchLevel') || {},
      hasTemporaryResidence: _get(data,'hasTemporaryResidence') || {},
      isLivingInCanada: _get(data,'isLivingInCanada') || {},
      education: _get(data,'education') || {},
      hasJobOffer: _get(data,'hasJobOffer') || {},
      foreignExperience: _get(data,'foreignExperience') || {},
      isWorkingInCanada: _get(data,'isWorkingInCanada') || {},
      workPermit: _get(data,'workPermit') || {},
      hasRelativesInCanada: _get(data,'hasRelativesInCanada') || {},
      hasFriendsInCanada: _get(data,'hasFriendsInCanada') || {},
      isAnEntrepreneur: _get(data,'isAnEntrepreneur') || {},
      family: _get(data,'family') || {},
    };
    return validData;
  }

  openDialog(uid:string, email:string) {
    const dialogRef = this.dialog.open(DataSavedComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.catalogService.createStripe(email,uid).subscribe((response) => {
        const url = _get(response,'url') || '';
        window.location.href = url;
      });
    });
  }

}