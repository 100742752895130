<app-loader *ngIf="isLoading"></app-loader>
<app-nav-bar></app-nav-bar>
<div class="container">
    <h1 class="title">Report</h1>
    <p class="introduction">In this section you will see your report generated based on your answers and Canadian immigration programs.</p>
    <div class="reports">
        <div *ngIf="path" class="container-report">
            <app-pdf-viewer [data]="path"></app-pdf-viewer>
        </div>
    </div>
</div>
