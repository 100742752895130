<mat-form-field appearance="outline">
  <mat-label>{{label}}
  </mat-label>
  <input type="text" matInput
      [placeholder]="placeholder"
      [formControl]="optionsFiltered"
      (change)="onCleanValue()"
      [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="OnOptionSelected($event.option)" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
  <mat-hint class="errorForm" *ngIf="errorFlag">
    {{label}} is required
  </mat-hint>
</mat-form-field>