import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICatalogItem, IFourSkills } from '../../form/models';
import { get as _get } from 'lodash';

@Component({
  selector: 'app-four-skills',
  templateUrl: './four-skills.component.html',
  styleUrls: ['./four-skills.component.scss']
})
export class FourSkillsComponent implements OnInit {

  @Input('options') options: any[];
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();

  public readingOption: ICatalogItem;
  public writtingOption: ICatalogItem;
  public listeningOption: ICatalogItem;
  public speakingOption: ICatalogItem;
  public defaultOption: any;

  constructor() { }

  ngOnInit(): void {
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.defaultOption = this.options.find(o => _get(o,'uid') === 0);
    if(this.defaultOption) {
      this.readingOption = this.defaultOption;
      this.writtingOption = this.defaultOption;
      this.listeningOption = this.defaultOption;
      this.speakingOption = this.defaultOption;
    }
    this.valueChanged();
  }

  onReadingChange(option:any) {
    this.readingOption = option;
    this.valueChanged();
  }

  onWrittingChange(option:any) {
    this.writtingOption = option;
    this.valueChanged();
  }

  onListeningChange(option:any) {
    this.listeningOption = option;
    this.valueChanged();
  }

  onSpeakingChange(option:any) {
    this.speakingOption = option;
    this.valueChanged();
  }

  valueChanged() {
    const data:IFourSkills = {
      reading: _get(this.readingOption,'uid') || 0,
      writing: _get(this.writtingOption,'uid') || 0,
      listening: _get(this.listeningOption,'uid') || 0,
      speaking: _get(this.speakingOption,'uid') || 0,
    }
    this.onValueChange.emit(data);
  }
}
