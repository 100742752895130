
<app-loader *ngIf="isLoading"></app-loader>
<app-nav-bar></app-nav-bar>
<div class="container-form">
    <div class="form">
        <mat-vertical-stepper [linear]="true" #stepper>
        <!-- <mat-step [completed]="isContactInformationComplete"> -->
            <mat-step [completed]="isContactInformationComplete">
                <ng-template matStepLabel><mat-icon class="icon-title">person</mat-icon>Personal information</ng-template>
                    <h3 class="section-title"><mat-icon class="icon-title">person</mat-icon>Personal information</h3>
                    <mat-divider class="section-space"></mat-divider>
                    <div class="two-columns">
                        <div class="label">
                            <mat-form-field appearance="outline" [color]="'primary'">
                                <mat-label>Name</mat-label>
                                <input matInput [(ngModel)]="name" (change)="validSubmitContact()" type="text" placeholder="Name">
                                <mat-hint *ngIf="!name && wasContactInformationClicked" class="errorForm">
                                    Name required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput [(ngModel)]="lastname" (change)="validSubmitContact()" type="text" placeholder="Last Name">
                                <mat-hint *ngIf="!lastname && wasContactInformationClicked" class="errorForm">
                                    Last Name required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput [(ngModel)]="email" (change)="validSubmitContact()" type="email" placeholder="Email">
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-hint *ngIf="!email && wasContactInformationClicked" class="errorForm">
                                    Email required
                                </mat-hint>
                                <mat-hint *ngIf="email && invalidEmail" class="errorForm">
                                    Invalid email address
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="two-columns">
                        <div class="label">
                            <app-select-country [label]="'Country Code'" [showCallingCode]="true" [eventSubmit]="submitContactInformation.asObservable()" (onValueChange)="onCountryCodeChange($event)"></app-select-country>
                        </div>
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Telephone</mat-label>
                                <input type="tel" matInput [(ngModel)]="phone" (change)="validSubmitContact()" placeholder="555-555-1234" placeholder="Phone Number">
                                <mat-icon matSuffix>call</mat-icon>
                                <mat-hint *ngIf="!phone && wasContactInformationClicked" class="errorForm">
                                    Phone Number required
                                </mat-hint>
                                </mat-form-field>
                        </div>
                    </div>
                    <div class="two-columns">
                        <div class="label">
                            <app-select-country [label]="'Country of Citizenship'" [eventSubmit]="submitContactInformation.asObservable()" (onValueChange)="onCountryChange($event)"></app-select-country>
                        </div>
                        <div class="label">
                            <app-select-date [label]="'Birthdate'" [eventSubmit]="submitContactInformation.asObservable()" (onValueChange)="onBirthdateChange($event)"></app-select-date>
                        </div>
                    </div>
                    <div class="one-columns">
                        <app-autocomplete
                            [label]="'Native language'"
                            [placeholder]="'Pick one'"
                            [options]="languages"
                            (onValueChange)="onNativeLanguageChange($event)"
                        ></app-autocomplete>
                    </div>
                    <div class="two-columns">
                        <div class="label">
                            <mat-form-field appearance="outline" [color]="'primary'">
                                <mat-label>Amount of funds available (CAD)</mat-label>
                                <input matInput [(ngModel)]="amountFunds" (change)="validSubmitContact()" type="number" placeholder="Amount of funds">
                                <mat-hint *ngIf="!amountFunds && wasContactInformationClicked" class="errorForm">
                                    Amount of funds available required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Number of relatives to migrate</mat-label>
                                <input matInput [(ngModel)]="relativesNumber" (change)="validSubmitContact()" type="number" placeholder="Number of relatives to migrate">
                                <mat-hint *ngIf="!relativesNumber && wasContactInformationClicked" class="errorForm">
                                    Number of relatives to migrate required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns" *ngIf="!defaultChannelID">
                        <mat-form-field appearance="outline">
                            <mat-label>How did you hear about us?</mat-label>
                            <mat-select (selectionChange)="onChannelChange($event)" [(value)]="defaultChannel" required>
                                <mat-option *ngFor="let option of channels" [value]="option">{{option.label}}</mat-option>
                            </mat-select>
                            <mat-hint *ngIf="!channel && wasContactInformationClicked" class="errorForm">
                                This field is required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchHasCriminalRecord()">Do you have a criminal record?</mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchHasUnresolvedDisputes()">Do you have unresolved legal disputes?</mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchHasDriverLicense()">Do you have a driver's license in your native country?</mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question">
                        <div class="content-column">
                            <mat-slide-toggle (change)="switchLivedColdWeather()">Have you previously or are you currently living in a community with a cold climate?</mat-slide-toggle>
                            <p class="help-notes">Cold weather defined as an average winter temperature of -10 ° C or less.</p>
                        </div>
                    </div>

                    <div class="one-columns">
                        <button class="action-button" [ngClass]="{'blocked': !isContactInformationComplete}" (click)="onSubmitContactInfo()" matStepperNext mat-button>Next</button>
                    </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">translate</mat-icon> Language Level</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">translate</mat-icon> Language Level</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchEnglishCertification()">Do you have any English certification?</mat-slide-toggle>
                </div>
                <div class="two-columns" *ngIf="hasEnglishCertificate">
                    <div class="label">
                        <div class="one-columns">
                            <app-select [label]="'Type of certification'" [options]="englishCertifications" (onValueChange)="onEnglishCertificationChange($event)"></app-select>
                        </div>
                    </div>
                    <div class="label" *ngIf="englishCertification && englishCertification.uid === 'Other'">
                        <mat-form-field appearance="outline">
                            <mat-label>Which?</mat-label>
                            <input matInput [(ngModel)]="otherEnglishCertificateName" type="text">
                        </mat-form-field>
                    </div>
                    <div class="label" *ngIf="englishCertification && englishCertification.uid === 'Duolingo'">
                        <mat-form-field appearance="outline">
                            <mat-label>Duolingo Poins</mat-label>
                            <input matInput [(ngModel)]="userDuolingoPoints" type="number">
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns" *ngIf="hasEnglishCertificate && englishCertification && englishCertification.uid === 'IELTS' ">
                    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserEnglishForSkills($event)"></app-four-skills>
                </div>
                <div class="one-columns" *ngIf="hasEnglishCertificate && englishCertification && englishCertification.uid === 'TOEFL' ">
                    <app-four-skills [options]="skillScores" (onValueChange)="onChangeUserEnglishForSkills($event)"></app-four-skills>
                </div>
                <div class="one-columns" *ngIf="englishCertification && englishCertification.uid === 'Other'">
                    <app-select [label]="'Level'" [options]="otherLanguageLevels" [default]="customLevelEnglish" (onValueChange)="onCustomLevelEnglishChange($event)"></app-select>
                </div>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchFrenchCertification()">Do you have any French certification?</mat-slide-toggle>
                </div>
                <div class="two-columns" *ngIf="hasFrenchCertificate">
                    <div class="label">
                        <div class="one-columns">
                            <app-select [label]="'Type of certification'" [options]="frenchCertifications" (onValueChange)="onFrenchCertificationChange($event)"></app-select>
                        </div>
                    </div>
                    <div class="label" *ngIf="frenchCertification && frenchCertification.uid === 'Other'">
                        <mat-form-field appearance="outline">
                            <mat-label>Which?</mat-label>
                            <input matInput [(ngModel)]="otherFrenchCertificateName" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns" *ngIf="hasFrenchCertificate && frenchCertification && frenchCertification.uid === 'TEF'">
                    <app-four-skills-french [options]="skillScoresFrenchTEF" (onValueChange)="onChangeUserFrenchForSkills($event)"></app-four-skills-french>
                </div>
                <div class="one-columns" *ngIf="hasFrenchCertificate && frenchCertification && frenchCertification.uid === 'TCF'">
                    <app-four-skills-french [options]="skillScoresFrenchTCF" (onValueChange)="onChangeUserFrenchForSkills($event)"></app-four-skills-french>
                </div>
                <div class="one-columns" *ngIf="frenchCertification && frenchCertification.uid === 'Other'">
                    <app-select [label]="'Level'" [options]="otherLanguageLevels" [default]="customLevelFrench" (onValueChange)="onCustomLevelFrenchChange($event)"></app-select>
                </div>
                <div class="one-columns">
                    <button class="action-button" mat-button (click)="onSubmitLanguage()">Next</button>
                </div>
            </mat-step>

            <mat-step>
                    <ng-template matStepLabel><mat-icon class="icon-title">house</mat-icon> Residence</ng-template>
                    <h3 class="section-title"><mat-icon class="icon-title">house</mat-icon> Residence</h3>
                    <mat-divider class="section-space"></mat-divider>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchTemporaryResidence()">
                            Do you have temporary residency status in Canada?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="hasTemporaryResidence">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>How many months are left to expire your residency?</mat-label>
                                <input matInput [(ngModel)]="residencyStatusMonthsLeft" (change)="validSubmitResidence()" type="number" placeholder="Months left">
                                <mat-hint *ngIf="!residencyStatusMonthsLeft && wasResidenceClicked" class="errorForm">
                                    Months left required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>                
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchLivingInCanada()">
                            Are you currently living in Canada?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question" *ngIf="isLivingInCanada">
                        <mat-slide-toggle (change)="switchValidResidence()">
                            Do you have a valid Canadian residence?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="isLivingInCanada">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>How many months have you been living in Canada?</mat-label>
                                <input matInput [(ngModel)]="monthsLivingInCanda" (change)="validSubmitResidence()" type="number" placeholder="Months living in Canada">
                                <mat-hint *ngIf="!monthsLivingInCanda && wasResidenceClicked && isLivingInCanada" class="errorForm">
                                    Months left required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns" *ngIf="isLivingInCanada">
                        <app-select [label]="'In which province do you live?'" [options]="provinces" (onValueChange)="onProvinceChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="isLivingInCanada && userLivingProvince && userLivingProvince.value == 'British Columbia'">
                        <app-select [label]="'In which regional district do you live?'" [options]="regionalDistricts" [default]="userLivingRegionalDistrict" (onValueChange)="onRegionalDistrictChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="isLivingInCanada">
                        <app-select [label]="'In which city do you live?'" [options]="cities" [default]="userLivingCity" (onValueChange)="onCityChange($event)"></app-select>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchHasCanidianDriverLicense()">
                            Do you have a Canadian driver's license?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchUserVolunteer()">
                            Are you a volunteer for a Canadian program?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchLivingInRuralArea()">
                            Have you lived or do you live in a Canadian rural area?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="livingInRuralArea">
                        <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onRuralAreaCityChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="livingInRuralArea">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Months living there?</mat-label>
                                <input matInput [(ngModel)]="monthsLivingInRuralArea" (change)="validSubmitResidence()" type="number" placeholder="Months living there">
                                <mat-hint *ngIf="!monthsLivingInRuralArea && wasResidenceClicked" class="errorForm">
                                    Months required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchLivingInColdClimate()">
                            Have you lived or do you live in a Canadian cold climate area?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="livingInColdClimate">
                        <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onColdClimateCityChange($event)"></app-select>
                    </div>
                    <div class="one-columns"  *ngIf="livingInColdClimate">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Months living there?</mat-label>
                                <input matInput [(ngModel)]="monthsLivingInColdClimate" (change)="validSubmitResidence()" type="number" placeholder="Months living there">
                                <mat-hint *ngIf="!monthsLivingInColdClimate && wasResidenceClicked" class="errorForm">
                                    Months required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchHasOwnProperty()">
                            Do you have your own property in Canada?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="hasOwnProperty">
                        <app-select [label]="'In which province?'" [options]="provinces" (onValueChange)="onUserPropertyProvinceChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="hasOwnProperty">
                        <app-select [label]="'In which city?'" [options]="cities" [default]="customLevelEnglish" (onValueChange)="onUserPropertyCityChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="hasOwnProperty">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Months living there?</mat-label>
                                <input matInput [(ngModel)]="monthsWithOwnProperty" (change)="validSubmitResidence()" type="number" placeholder="Months living there">
                                <mat-hint *ngIf="!monthsWithOwnProperty && wasResidenceClicked" class="errorForm">
                                    Months required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns radio-question">
                        <mat-slide-toggle (change)="switchPreviouslyTraveled()">
                            Have you previously traveled to Canada?
                        </mat-slide-toggle>
                    </div>
                    <div class="one-columns" *ngIf="previouslyTraveled">
                        <app-select [label]="'In which province?'" [options]="provinces" (onValueChange)="onTravelProvinceChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="previouslyTraveled && userTravelProvince && userTravelProvince.value == 'British Columbia'">
                        <app-select [label]="'In which regional district do you live?'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictTravelChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="previouslyTraveled">
                        <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onCityTravelChange($event)"></app-select>
                    </div>
                    <div class="one-columns" *ngIf="previouslyTraveled">
                        <div class="label">
                            <mat-form-field appearance="outline">
                                <mat-label>Months living there?</mat-label>
                                <input matInput [(ngModel)]="previouslyTraveledMonths" (change)="validSubmitResidence()" type="number" placeholder="Months">
                                <mat-hint *ngIf="!previouslyTraveledMonths && wasResidenceClicked" class="errorForm">
                                    Months required
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="one-columns">
                        <button class="action-button" mat-button (click)="onSubmitResidence()" matStepperNext>Next</button>
                    </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">school</mat-icon> Education</ng-template>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns">
                    <app-select [label]="'What is your last degree of studies'" [options]="schoolLeves" (onValueChange)="onLastDegreeChange($event)"></app-select>
                </div>
                <div class="one-columns">
                    <app-autocomplete
                    [label]="'What occupation did you study?'"
                    [placeholder]="'Pick one'"
                    [options]="occupations"
                    [eventSubmit]="submitEducation.asObservable()"
                    (onValueChange)="onEducationOccupationChange($event)">
                    </app-autocomplete>
                </div>
                <div class="one-columns">
                    <app-select [label]="'Sector'" [options]="sectors" (onValueChange)="onEducationSectorChange($event)"></app-select>
                </div>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchHasCanadaStudies()">Have you studied in Canada?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="hasCanadaStudies">
                    <app-select [label]="'In which province do you live?'" [options]="provinces" (onValueChange)="onEducationProvinceChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasCanadaStudies">
                    <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onEducationCityChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasCanadaStudies">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months studing in Canada?</mat-label>
                            <input matInput [(ngModel)]="monthsEducationTime" (change)="validSubmitEducation()" type="number" placeholder="Months studing there">
                            <mat-hint *ngIf="!monthsEducationTime && wasEducationClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns" *ngIf="hasCanadaStudies">
                    <app-select [label]="'Education type?'" [options]="studiesTypes" (onValueChange)="onEducationTypeChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasCanadaStudies">
                    <app-select [label]="'School'" [options]="schools" (onValueChange)="onEducationSchoolChange($event)"></app-select>
                </div>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchHasPostSecundaryStudies()">Did you study or are you studying post-secondary education?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="hasPostSecundaryStudies">
                    <app-select [label]="'What is your last post-secundary degree'" [options]="schoolLeves" (onValueChange)="onPostSecundaryDegreeChange($event)"></app-select>
                </div>
                <div class="one-columns radio-question" *ngIf="hasPostSecundaryStudies">
                    <mat-slide-toggle (change)="switchHasCanadaPostSecundaryStudies()">Did you have a post-secondary education in Canada?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="hasPostSecundaryStudies && hasCanadaPostSecundaryStudies">
                    <app-select [label]="'In which province do you live?'" [options]="provinces" (onValueChange)="onProvincePostSecundaryChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasPostSecundaryStudies && hasCanadaPostSecundaryStudies">
                    <app-select [label]="'In which city?'" [options]="cities" (onValueChange)="onCityPostSecundaryChange($event)"></app-select>
                </div>
                <div class="one-columns radio-question" *ngIf="hasPostSecundaryStudies && hasCanadaPostSecundaryStudies">
                    <mat-slide-toggle (change)="switchITABC()">Are you studying or did you study at Industry Training Authority BC?</mat-slide-toggle>
                </div>
                <div class="one-columns">
                    <button class="action-button" mat-button matStepperNext (click)="onSubmitEducation()">Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">work</mat-icon> Job Offer</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">work</mat-icon> Job Offer</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchJobOffer()">Do you have a canadian job offer?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <app-select [label]="'In which province do you have the offer?'" [options]="provinces" (onValueChange)="onProvinceJobOfferChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer && provinceJobOffer && provinceJobOffer.value == 'British Columbia'">
                    <app-select [label]="'In which regional district do you have the offer?'" [options]="regionalDistricts" (onValueChange)="onRegionJobOfferChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <app-select [label]="'In which city do you have the offer?'" [options]="cities" (onValueChange)="onCityJobOfferChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>How many hours per week is the offer?</mat-label>
                            <input matInput [(ngModel)]="offerHours" (change)="validSubmitJobOffer()" type="number" placeholder="Hours per week">
                            <mat-hint *ngIf="!offerHours && wasJobOfferClicked" class="errorForm">
                                Hours is required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <app-autocomplete
                    [label]="'What is the occupation of the job offer?'"
                    [placeholder]="'Pick one'"
                    [options]="occupations"
                    [eventSubmit]="submitJobOffer.asObservable()"
                    (onValueChange)="onJobOfferOccupationChange($event)">
                    </app-autocomplete>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <app-select [label]="'Offer type?'" [options]="studiesTypes" (onValueChange)="onOfferTypeChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasAnOffer">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Month Wage</mat-label>
                            <input matInput [(ngModel)]="offerMonthWage" (change)="validSubmitJobOffer()" type="number" placeholder="Hours per week">
                            <mat-hint *ngIf="!offerMonthWage && wasJobOfferClicked" class="errorForm">
                                Month wage is required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns radio-question" *ngIf="hasAnOffer">
                    <mat-slide-toggle (change)="switchIndefiniteOffer()">Is it an indefinite offer?</mat-slide-toggle>
                </div>
                <div class="one-columns radio-question" *ngIf="hasAnOffer">
                    <mat-slide-toggle (change)="switchHasAMentor()">Do you have a mentor assigned in the offer?</mat-slide-toggle>
                </div>
                <div class="one-columns">
                    <button class="action-button" mat-button (click)="onSubmitJobOffer()" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">apartment</mat-icon> Work Experience</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">apartment</mat-icon> Work Experience</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months of experience working outside of Canada</mat-label>
                            <input matInput [(ngModel)]="monthsOfExperienceOutside" (change)="validSubmitWorkExperience()" type="number" placeholder="Months of experience working outside of Canada">
                            <mat-hint *ngIf="!monthsOfExperienceOutside && wasWorkExperienceClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>  
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchHasACanadianWorkPermit()">Do you have a work permit in Canada?</mat-slide-toggle>
                </div>
                <div class="one-column" *ngIf="hasACanadianWorkPermit">
                    <app-select [label]="'Work experience permit'" [options]="workPermits" (onValueChange)="onWorkPermitChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="hasACanadianWorkPermit">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Remaining months</mat-label>
                            <input matInput [(ngModel)]="workPermitRemainingMonths" (change)="validSubmitWorkExperience()" type="number" placeholder="Months">
                            <mat-hint *ngIf="!workPermitRemainingMonths && wasWorkExperienceClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchWorkingInCanada()">Are you currently working in Canada?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <app-select [label]="'In which province do you work?'" [options]="provinces" (onValueChange)="onProvinceWorkChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada && provinceWork && provinceWork.value == 'British Columbia'">
                    <app-select [label]="'In which regional district do you work?'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictWorkChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <app-select [label]="'In which city do you work?'" [options]="cities" (onValueChange)="onCityWorkChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <app-select [label]="'Sector'" [options]="sectors" (onValueChange)="onSectorWork($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <app-autocomplete
                    [label]="'What occupation did you work?'"
                    [placeholder]="'Pick one'"
                    [options]="occupations"
                    [eventSubmit]="submitWorkExperience.asObservable()"
                    (onValueChange)="onWorkOccupationChange($event)">
                    </app-autocomplete>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <app-select [label]="'Job type'" [options]="studiesTypes" (onValueChange)="onWorkTypeChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Hours per week</mat-label>
                            <input matInput [(ngModel)]="workHoursPerWeek" (change)="validSubmitWorkExperience()" type="number" placeholder="Hours per week">
                            <mat-hint *ngIf="!workHoursPerWeek && wasWorkExperienceClicked" class="errorForm">
                                Hours required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Wage per hour</mat-label>
                            <input matInput [(ngModel)]="wagePerHour" (change)="validSubmitWorkExperience()" type="number" placeholder="Wage per hour">
                            <mat-hint *ngIf="!wagePerHour && wasWorkExperienceClicked" class="errorForm">
                                Wage required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Month wage</mat-label>
                            <input matInput [(ngModel)]="wagePerMonth" (change)="validSubmitWorkExperience()" type="number" placeholder="Wage per month">
                            <mat-hint *ngIf="!wagePerMonth && wasWorkExperienceClicked" class="errorForm">
                                Month wage required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months of experience</mat-label>
                            <input matInput [(ngModel)]="monthsOfExperience" (change)="validSubmitWorkExperience()" type="number" placeholder="Months">
                            <mat-hint *ngIf="!monthsOfExperience && wasWorkExperienceClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns" *ngIf="isWorkingInCanada">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months of experience in the last 18 months</mat-label>
                            <input matInput [(ngModel)]="monthsOfExperience18" (change)="validSubmitWorkExperience()" type="number" placeholder="Months">
                            <mat-hint *ngIf="!monthsOfExperience18 && wasWorkExperienceClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchAdditionalWorkExperience()">Do you have additional work experience?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <app-select [label]="'In which province did you work?'" [options]="provinces" (onValueChange)="onProvinceExtraWork($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience && provinceExtraWorkExperience && provinceExtraWorkExperience.value == 'British Columbia'">
                    <app-select [label]="'In which regional district did you work?'" [options]="regionalDistricts" (onValueChange)="onRegionalDistrictExtraWorkChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <app-select [label]="'In which city did you work?'" [options]="cities" (onValueChange)="onCityExtraWorkChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <app-autocomplete
                    [label]="'What occupation did you work?'"
                    [placeholder]="'Pick one'"
                    [options]="occupations"
                    [eventSubmit]="submitWorkExperience.asObservable()"
                    (onValueChange)="onExtraWorkExperienceChange($event)">
                    </app-autocomplete>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months of experience</mat-label>
                            <input matInput [(ngModel)]="extraMonthsExperience" (change)="validSubmitWorkExperience()" type="number" placeholder="Months">
                            <mat-hint *ngIf="!extraMonthsExperience && wasWorkExperienceClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <app-select [label]="'Job type'" [options]="studiesTypes" (onValueChange)="onExtraWorkTypeChange($event)"></app-select>
                </div>
                <div class="one-columns" *ngIf="additionalWorkExperience">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Month wage</mat-label>
                            <input matInput [(ngModel)]="monthsWageExtraExperience" (change)="validSubmitWorkExperience()" type="number" placeholder="Months">
                            <mat-hint *ngIf="!monthsWageExtraExperience && wasWorkExperienceClicked" class="errorForm">
                                Month wage required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns">
                    <button class="action-button" mat-button (click)="onSubmitWorkExperience()" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">groups</mat-icon> Relatives in Canada</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">groups</mat-icon> Relatives in Canada</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns">
                    <div class="label">
                        <app-select [label]="'How many relatives do you have living in Canada?'" [options]="numbersOptions" [eventSubmit]="submitRelativesInCanada.asObservable()" (onValueChange)="onDirectRelativesChange($event)"></app-select>
                    </div>
                </div>
                <div class="one-columns" *ngIf="directRelativesInCanada && directRelativesInCanada > 0">
                    <app-get-relatives [amount]="directRelativesInCanada"  (onSubmitClicked)="onSubmitRelatives($event)"></app-get-relatives>
                </div>
                <div class="one-columns" *ngIf="!directRelativesInCanada || directRelativesInCanada === 0">
                    <button class="action-button" mat-button matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">groups</mat-icon> Friends in Canada</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">groups</mat-icon> Friends in Canada</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns">
                    <div class="label">
                        <app-select [label]="'How many friends do you have living in Canada?'" [options]="numbersOptions" [eventSubmit]="submitFriendsInCanada.asObservable()" (onValueChange)="onFriendsChange($event)"></app-select>
                    </div>
                </div>
                <div class="one-columns" *ngIf="friendsInCanada && friendsInCanada > 0">
                    <app-get-friends [amount]="friendsInCanada"  (onSubmitClicked)="onSubmitFriends($event)"></app-get-friends>
                </div>
                <div class="one-columns" *ngIf="!friendsInCanada || friendsInCanada === 0">
                    <button class="action-button" mat-button matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">business</mat-icon> Entrepreneur</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">business</mat-icon> Entrepreneur</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchEntrepreneur()">Are you an entrepreneur?</mat-slide-toggle>
                </div>
                <div class="one-columns" *ngIf="isAnEntrepreneur">
                    <app-select [label]="'Sector'" [options]="sectors" (onValueChange)="onEntrepreneurSectorChange($event)"></app-select>
                </div>
                <div class="two-columns" *ngIf="isAnEntrepreneur">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Months of experience</mat-label>
                            <input matInput [(ngModel)]="entrepreneurMonths" (change)="validSubmitEntrepreneur()" type="number" placeholder="Months of experience">
                            <mat-hint *ngIf="!entrepreneurMonths && wasEntrepreneurClicked" class="errorForm">
                                Months required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Number of jobs created</mat-label>
                            <input matInput [(ngModel)]="numberJobs" (change)="validSubmitEntrepreneur()" type="number" placeholder="Number of jobs created">
                            <mat-hint *ngIf="!numberJobs && wasEntrepreneurClicked" class="errorForm">
                                Number of jobs required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="one-columns" *ngIf="isAnEntrepreneur">
                    <div class="label">
                        <mat-form-field appearance="outline">
                            <mat-label>Amount Invested in CAD</mat-label>
                            <input matInput [(ngModel)]="amountInvested" (change)="validSubmitEntrepreneur()" type="number" placeholder="Amount Invested in CAD">
                            <mat-hint *ngIf="!amountInvested && wasEntrepreneurClicked" class="errorForm">
                                Amount required
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="one-columns radio-question" *ngIf="isAnEntrepreneur">
                    <mat-slide-toggle (change)="switchIsRuralBusiness()">Is your business rural?</mat-slide-toggle>
                </div>
                <div class="one-columns radio-question" *ngIf="isAnEntrepreneur">
                    <mat-slide-toggle (change)="switchIsExportOriented()">Is your business export oriented?</mat-slide-toggle>
                </div>
                <div class="one-columns radio-question" *ngIf="isAnEntrepreneur">
                    <mat-slide-toggle (change)="switchIsAExistingBusiness()">Is your business an existing business in Canada?</mat-slide-toggle>
                </div>

                <div class="one-columns">
                    <button class="action-button" mat-button matStepperNext (click)="onSubmitEntrepreneur()">Next</button>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">family_restroom</mat-icon> Family</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">family_restroom</mat-icon> Family</h3>
                <mat-divider class="section-space"></mat-divider>
                <app-family (onSubmitClicked)="onSubmitFamily($event)"></app-family>
            </mat-step>
        
            <mat-step>
                <ng-template matStepLabel><mat-icon class="icon-title">favorite</mat-icon> Partner</ng-template>
                <h3 class="section-title"><mat-icon class="icon-title">favorite</mat-icon> Partner</h3>
                <mat-divider class="section-space"></mat-divider>
                <div class="one-columns radio-question">
                    <mat-slide-toggle (change)="switchFormalPartner()">Do you currently have a formal partner?</mat-slide-toggle>
                </div>
                <app-partner *ngIf="hasFormalPartner" (onSubmitClicked)="onSubmitPartner($event)"></app-partner>
                <div *ngIf="!hasFormalPartner" class="one-columns">
                    <button class="action-button" mat-button matStepperNext (click)="onSubmitPartner()">Next</button>
                </div>
            </mat-step>

        </mat-vertical-stepper>
    </div>    
</div>