import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {

  @Input('options') options: any[];
  @Input('label') label: string = "";
  @Input('placeholder') placeholder: string = "";
  @Input('eventSubmit') eventSubmit: Observable<void>;
  @Output() onValueChange = new EventEmitter();
  
  public errorFlag:boolean = false;
  public currentValue: any;
  public selected: any;
  public defaultValue: any;

  constructor() { }

  ngOnInit(): void {
    if(this.defaultValue) {
      this.selected = this.defaultValue;
    }
    if(this.eventSubmit) {
      this.eventSubmit.subscribe(() => this.validRequired());
    }
  }

  OnOptionSelected(selected:any) {
    const selectedValue = selected && selected.value ? selected.value : null;
    this.currentValue = selectedValue;
    this.validRequired();
    this.onValueChange.emit(selectedValue);    
  }

  validRequired() {
    if(!this.currentValue) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
    }
  }

  @Input()
  set default(value: any) {
    this.defaultValue = value;
    this.selected = this.defaultValue;
  }

}
