import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../../form/catalog.service';
import { ICatalogItem, ICity, ISimpleCatalog } from '../../form/models';

@Component({
  selector: 'app-get-friends',
  templateUrl: './get-friends.component.html',
  styleUrls: ['./get-friends.component.scss']
})
export class GetFriendsComponent implements OnInit {

  @Input('amount') amount: number;
  @Output() onSubmitClicked: EventEmitter<any> = new EventEmitter();
  dynamicForm: FormGroup;
  submitted = false;
  
  public provinces: ISimpleCatalog[];
  public regionalDistricts: ISimpleCatalog[];
  public cities: ICity[];

  constructor(private formBuilder: FormBuilder, public catalogService: CatalogService) {}

  ngOnInit(): void {
    this.catalogService.getCities().subscribe(cities => {
      this.cities =  cities ? cities : [];
    });
    this.catalogService.getProvinces().subscribe(provinces => {
      this.provinces =  provinces ? provinces : [];
    });
    this.catalogService.getRegionalDistricts().subscribe(districts => {
      this.regionalDistricts =  districts ? districts : [];
    });
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void{
    const amount = changes && changes.amount ? changes.amount : null;
    if(amount && !amount.isFirstChange() && amount.currentValue !== amount.previousValue) {
      this.onChangeChildren(amount.currentValue);
    }
  }

  buildForm() {
    this.dynamicForm = this.formBuilder.group({
      children: new FormArray([])
    });
    this.onChangeChildren(this.amount)
  }

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get c() { return this.f.children as FormArray; }

  onChangeChildren(numberOfChildren:number = 0) {
    if (this.c.length < numberOfChildren) {
        for (let i = this.c.length; i < numberOfChildren; i++) {
            this.c.push(this.formBuilder.group({
                name: ['', Validators.required],
                age: ['', [Validators.required]],
                months: ['', [Validators.required]],
                province: ['', [Validators.required]],
                regionalDistrict: [''],
                city: ['', [Validators.required]],
            }));
        }
    } else {
        for (let i = this.c.length; i >= numberOfChildren; i--) {
            this.c.removeAt(i);
        }
    }
  }

  onDataChange(i:number, data:any) {
    this.dynamicForm.getRawValue();
  }

  onSubmit() {    
    this.submitted = true;
    if (this.dynamicForm.invalid) {
        return;
    }
    this.onSubmitClicked.next(this.dynamicForm.getRawValue());
  }

  onProvinceChange(i:number, value:any) {
    const selectedFormGroup = this.c.controls[i] as FormGroup;
    selectedFormGroup.controls['province'].setValue(value.value);
    this.dynamicForm.updateValueAndValidity();
  }

  onRegionalDistrictChange(i:number, value:any) {
    const selectedFormGroup = this.c.controls[i] as FormGroup;
    selectedFormGroup.controls['regionalDistrict'].setValue(value.value);
    this.dynamicForm.updateValueAndValidity();
  }

  onCitiesChange(i:number, value:any) {
    const selectedFormGroup = this.c.controls[i] as FormGroup
    selectedFormGroup.controls['city'].setValue(value.name);
    this.dynamicForm.updateValueAndValidity();
  }

}