import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogService } from '../../form/catalog.service';
import { PdfViewerFullCompleteComponent } from '../pdf-viewer-full-complete/pdf-viewer-full-complete.component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input("data") data: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public path: string;
  public file: Blob;
  public pdfSrc: string;
  public currentPage: number = 1;
  public totalPages: number;
  public scale: number = 1;
  public rotationAmount: number = 0;

  constructor(
    public catalogService: CatalogService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.catalogService.getFileSource(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(file => {
        this.file = new Blob([file], { type: "application/pdf" });
        this.pdfSrc = URL.createObjectURL(this.file);
      }, error => { console.log(error);});
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fileLoaded(data: any) {
    if (data && data._pdfInfo) {
      this.totalPages = data._pdfInfo.numPages;
    }
  }

  zoomIn() {
    if (this.scale < 3) {
      this.scale = this.scale + 0.5;
    }
  }

  zoomOut() {
    if (this.scale > 1) {
      this.scale = this.scale - 0.5;
    }
  }

  rotate() {
    if (this.rotationAmount < 360) {
      this.rotationAmount = this.rotationAmount + 90;
    } else {
      this.rotationAmount = 90;
    }
  }

  download() {
    window.open(this.pdfSrc);
  }

  preview() {
    if (this.totalPages > 1) {
      if (1 < this.currentPage) {
        this.currentPage = this.currentPage - 1;
      } else {
        this.currentPage = this.totalPages;
      }
    }
  }

  next() {
    if (this.totalPages > 1) {
      const newIndex = this.currentPage + 1;
      if (this.totalPages >= newIndex) {
        this.currentPage = newIndex;
      } else {
        this.currentPage = 1;
      }
    }
  }

  open() {
    if (this.totalPages) {
      const config = {
        height: "100%",
        width: "100%",
        panelClass: "full-screen-modal",
        data: {
          currentPage: this.currentPage,
          pdfSrc: this.pdfSrc
        }
      };
      const dialogRef = this.dialog.open(PdfViewerFullCompleteComponent, config);
      dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {});
    }
  }

}
