import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { HomeComponent } from './components/home/home.component';
import { NotprocessedComponent } from './components/notprocessed/notprocessed.component';
import { ViewerComponent } from './components/viewer/viewer.component';

const routes: Routes = [
  { path: "form", component: FormComponent },
  { path: 'form/:id', component: FormComponent },
  { path: "viewer", component: ViewerComponent },
  { path: "viewer/:id", component: ViewerComponent },
  { path: "home", component: HomeComponent},
  { path: 'home/:id', component: HomeComponent },
  { path: 'payment-not-processed', component: NotprocessedComponent },
  { path: '**', component: HomeComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
