<div class="one-columns">
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>How many members are in your core family</mat-label>
            <input matInput [(ngModel)]="familySize" type="number" placeholder="Family Size">
            <mat-hint *ngIf="!familySize && wasSubmitClicked" class="errorForm">
                Family size required
            </mat-hint>
        </mat-form-field>
    </div>
    <div class="label">
        <mat-form-field appearance="outline">
            <mat-label>How many children do you have?</mat-label>
            <input matInput [(ngModel)]="childrenNumber" type="number" placeholder="Family Size">
            <mat-hint *ngIf="!childrenNumber && wasSubmitClicked" class="errorForm">
                Family size required
            </mat-hint>
        </mat-form-field>
    </div>
    <app-get-children [amount]="childrenNumber" (onSubmitClicked)="onSubmitFamily($event)"></app-get-children>
</div>