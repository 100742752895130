import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../form/catalog.service';
import { get as _get } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notprocessed',
  templateUrl: './notprocessed.component.html',
  styleUrls: ['./notprocessed.component.scss']
})
export class NotprocessedComponent implements OnInit {

  public isLoading: boolean;

  constructor(public catalogService: CatalogService, private router: Router) { }

  ngOnInit(): void {
    
  }

  openPayment() {
    this.isLoading = true;
    const uid = localStorage.getItem('data');
    const email = localStorage.getItem('email');
    if(uid && email) {
      this.catalogService.createStripe(email,uid).subscribe((response) => {
        const url = _get(response,'url') || '';
        this.isLoading = false;
        window.location.href = url;
      });
    } else {
      this.router.navigate(['/form']);
    }

  }

  

}
