import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit {

  @Input('options') options: any[];
  @Input('label') label: string = "";
  @Input('placeholder') placeholder: string = "";
  @Output() onValueChange = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  OnOptionSelected(selected:any) {
    const selectedValue = selected && selected.value ? selected.value : null;
    this.onValueChange.emit(selectedValue);    
  }

}
