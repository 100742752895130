import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input('options') options: any[];
  @Input('label') label: string = "";
  @Input('placeholder') placeholder: string = "";
  @Input('eventSubmit') eventSubmit: Observable<void>;
  @Output() onValueChange = new EventEmitter();

  filteredOptions: Observable<string[]>;
  public optionsFiltered = new FormControl();
  public errorFlag:boolean = false;
  public currentValue: any;
  
  constructor() { }

  ngOnInit(): void {
    if(this.eventSubmit) {
      this.eventSubmit.subscribe(() => this.validRequired());
    }
    this.filteredOptions = this.optionsFiltered.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: any): string[] {    
    if(value && typeof(value) == 'string' ) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.label && option.label.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }
  }

  OnOptionSelected(selected:any) {
    const selectedValue = selected && selected.value ? selected.value : null;
    this.currentValue = selectedValue;
    this.validRequired();
    this.onValueChange.emit(selectedValue);    
  }

  onCleanValue() {
    this.currentValue = null;
    this.validRequired();
    this.onValueChange.emit(null);    
  }

  displayFn(option: any): string {
    return option && option.label ? option.label : '';
  }

  validRequired() {
    if(!this.currentValue) {
      this.errorFlag = true;
    } else {
      this.errorFlag = false;
    }
  }
}
