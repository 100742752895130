import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CatalogService } from '../form/catalog.service';
import { get as _get } from 'lodash';
import { DomSanitizer } from "@angular/platform-browser";
import { ErrorService } from '../shared/error/error.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  public isLoading: boolean = false;
  public path: string;

  constructor(
    public catalogService: CatalogService,
    public router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap
    .subscribe((params: ParamMap) => {
      let uid = params.get('id') || localStorage.getItem('data');
      if(!uid) {
        this.router.navigate(['/form']);
      }
      this.catalogService.getUserReport(uid).subscribe(data => {
        setTimeout(() => {
          this.path = _get(data,'path') || '';
          this.isLoading = false;
        }, 0);
      }, err => {this.isLoading = false; this.errorService.showError(err);});
    }, err => {this.isLoading = false; this.errorService.showError(err);});
  }

  getPath() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.path);
  }

}
